import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
// import { number } from "../../utils/validation/ValidateField";
import SidebarLinkGroup from "./SidebarLinkGroup";
import { FieldMaintenanceIcon } from "../../components/SVGConstants";
import { IsSuperReportDisable } from "../../utils/AppSetting";

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");

  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? true : storedSidebarExpanded === "false"
  );


  // Set Role
  const [role, setRoleId] = useState(0);

  useEffect(() => {
    let cookie = getAuthProps();
    const roleId = cookie && cookie.roleId ? Number(cookie.roleId) : 0;
    if (roleId > 0) {
      setRoleId(roleId);
    }
  }, []);

  // End Role
  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.remove("sidebar-expanded");
    } else {
      document.querySelector("body").classList.add("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div className="main-sidebar-sections">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-white bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 
        ${sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto 
        lg:translate-x-0 transform 2xl:sidebar-expanded:!w-80 lg:sidebar-expanded:!w-60 2xl:!w-80 lg:w-[15rem] w-[15rem]  shrink-0 
        bg-white border-r transition-all duration-200 ease-in-out ${sidebarOpen ? "translate-x-0" : "translate-x-0"
          }`}
      >
        {/* Expand / collapse button */}
        <div className="2xl:hidden lg:hidden hidden sm:inline-flex justify-end toggle-btn-sec">
          <div
            className="py-2"
            onClick={() => setSidebarExpanded(!sidebarExpanded)}
          >
            <img
              className="rotate-180 sidebar-expanded:rotate-0 w-6"
              src="/navbar-toggle.png"
            />
          </div>
        </div>
        {/* Expand / collapse button */}
        {/* Sidebar header */}
        <div className="flex justify-between 2xl:p-[25px] lg:p-[15px] p-[10px] border-b ">
          {/* Logo */}
          <NavLink end to="Dashboard" className="block">
            <img src="/logo.png" alt="" />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8 px-4 pt-3 xl:mt-6 lg:mt-6 mt-0  pb-8 overflow-y-scroll lg:overflow-y-auto no-scrollbar sidebar-menu-list">
          {/* Pages group */}
          <div>
            <ul className="mt-3">
              {/* Normal Menu Start*/}
              <li
                className={`custom-make-menu rounded-sm 2xl:mb-12 lg:mb-[40px] mb-[20px] last:mb-0 ${pathname.includes("dashboard") && "bg-white"
                  }`}
              >
                <NavLink
                  end
                  to="Dashboard"
                  className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 ${pathname.includes("dashboard") && "hover:theme-text-color"
                    }`}
                >
                  <div className="flex menu-icon-img">
                    <svg
                      className="menu-svg"
                      width="24"
                      height="24"
                      viewBox="0 0 513 513"
                      fill={"#6e6e6e"}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1_4)">
                        <path
                          d="M256.009 42.671C256.007 42.671 256.004 42.671 256.002 42.671C256.001 42.671 256.001 42.671 256 42.671C255.999 42.671 255.998 42.671 255.997 42.671C185.326 42.674 121.349 71.308 75.03 117.606C75.014 117.622 74.996 117.635 74.98 117.651C74.963 117.668 74.95 117.686 74.933 117.703C28.688 163.976 0.072 227.867 0.011 298.445C0.011 298.521 0 298.595 0 298.671C0 298.744 0.01 298.814 0.011 298.886C0.061 359.087 20.973 416.125 58.526 461.59C62.579 466.497 68.61 469.338 74.974 469.338H437.022C443.386 469.338 449.417 466.497 453.47 461.59C491.077 416.06 512.009 358.94 512.009 298.671C512.009 157.289 397.391 42.671 256.009 42.671ZM426.68 426.671H85.316C62.035 395.694 47.604 359.01 43.733 320.004H106.667C118.449 320.004 128 310.453 128 298.671C128 286.889 118.449 277.338 106.667 277.338H43.734C47.993 234.433 64.964 195.272 90.825 163.667L105.145 177.987C113.476 186.318 126.984 186.318 135.315 177.987C143.646 169.656 143.646 156.148 135.315 147.817L120.994 133.496C152.599 107.632 191.759 90.659 234.666 86.398V149.339C234.666 161.121 244.217 170.672 255.999 170.672C267.781 170.672 277.332 161.121 277.332 149.339V86.396C320.238 90.655 359.4 107.628 391.008 133.492L376.683 147.817C368.352 156.148 368.352 169.656 376.683 177.987C385.014 186.318 398.522 186.318 406.853 177.987L421.179 163.661C447.046 195.268 464.021 234.432 468.282 277.338H405.332C393.55 277.338 383.999 286.889 383.999 298.671C383.999 310.453 393.55 320.004 405.332 320.004H468.282C464.409 359.001 449.97 395.686 426.68 426.671Z"
                          fill="#6e6e6e"
                        />
                        <path
                          d="M190.102 225.069L277.258 326.545L309.124 280.526L190.102 225.069Z"
                          fill="#6e6e6e"
                        />
                        <path
                          d="M320 362.671H192C180.218 362.671 170.667 372.222 170.667 384.004C170.667 395.786 180.218 405.337 192 405.337H320C331.782 405.337 341.333 395.786 341.333 384.004C341.333 372.222 331.782 362.671 320 362.671Z"
                          fill="#6e6e6e"
                        />
                      </g>
                    </svg>

                    <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Admin Dashboard
                    </p>
                  </div>
                </NavLink>
              </li>
              {/* Normal Menu  End*/}

              {/*  */}
              {role > 0 && (role === 1 || role === 8) ? (
                <>
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/" || pathname.includes("Resource")
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/CreateAdminAccount"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }  
                        ${pathname === "/ViewEditAdminAccount"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex menu-icon-img">
                              <svg
                                className="menu-svg"
                                width="24"
                                height="24"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 490 490"
                              >
                                <g>
                                  <g>
                                    <path
                                      d="M311.5,184.4c0,36.1-29.2,65.3-65.3,65.3s-65.3-29.2-65.3-65.3s29.2-65.3,65.3-65.3S311.5,148.4,311.5,184.4z M245,0
                            C109.9,0,0,109.9,0,245s109.9,245,245,245s245-109.9,245-245S380.1,0,245,0z M357.3,404.7c-8.2-65.7-35.2-133-111.1-133
                            c-76.5,0-103.3,68.3-111.3,134.5C83.5,371,49.7,311.9,49.7,245c0-107.7,87.6-195.3,195.3-195.3S440.3,137.3,440.3,245
                            C440.3,310.9,407.5,369.3,357.3,404.7z"
                                      fill="#6e6e6e"
                                    />
                                  </g>
                                </g>
                              </svg>
                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Account Management
                              </p>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="CreateAdminAccount"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CreateAdminAccount"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Create Account
                                  </span>
                                </NavLink>
                              </li>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="ViewEditAdminAccount"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewEditAdminAccount"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View/Edit Account
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
              ) : null}
              {role === 11 ||
                role === 2 ||
                role === 10 ||
                role === 7 ||
                role === 9 ||
                role === 1 ||
                role === 12 ||
                role === 13 ||
                role === 14 ? (
                <>
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/" || pathname.includes("Resource")
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            // className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150
                            // ${
                            //   (pathname === "/Resource/" ||
                            //     pathname.includes("Resource")) &&
                            //   "hover:theme-text-color"
                            // }`}
                            className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  ${pathname === "/CreatePages"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                              }  ${pathname === "/ViewPages"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-basline justify-between">
                              <div className="flex menu-icon-img">
                                <svg
                                  className="menu-svg"
                                  width="24"
                                  height="24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 324 324"
                                >
                                  <g transform="translate(0,324) scale(0.100000,-0.100000)">
                                    <path
                                      d="M335 3216 c-48 -22 -77 -60 -82 -110 -7 -55 8 -95 48 -130 l29 -26 1305 0 1305 0 30 33 c55 60 62 127 20 187 -45 64 41 60 -1357 60 -1034 -1 -1274 -3 -1298 -14z"
                                      fill="#6e6e6e"
                                    />
                                    <path
                                      d="M320 2027 c0 -934 -2 -910 54 -877 34 20 536 481 536 491 0 5 17 20 37 33 20 14 80 68 134 121 85 82 102 95 132 95 41 0 70 -28 65 -64 -2 -16 -102 -134 -297 -348 l-295 -323 1090 -3 c749 -2 1101 0 1126 8 33 9 37 14 43
                                  53 3 23 5 407 3 852 l-3 810 -1312 3 -1313 2 0 -853z m2258 699 c15 -8 38 -26 52 -41 l25 -27 3 -566 c2 -535 1 -569 -16 -601 -36 -67 -132 -91 -199 -50 -65 40 -63 20 -63 640 0 545 1 563 20 594 36 58 119 82 178 51z m-436 -297 c9 -5
                                  30 -26 45 -46 l28 -36 0 -416 c0 -382 -1 -419 -18 -449 -28 -51 -72 -74 -135 -69 -46 3 -59 9 -90 41 l-37 36 -3 422 c-2 296 1 430 9 449 29 72 130 105 201 68z m-474 -209 c18 -11 41 -34 52 -52 19 -31 20 -51 20 -338 0 -287 -1 -307
                                  -20 -338 -62 -102 -204 -94 -251 13 -19 43 -20 64 -17 338 3 259 5 296 21 325 37 68 128 92 195 52z"
                                      fill="#6e6e6e"
                                    />
                                    <path
                                      d="M725 1398 c-296 -273 -483 -454 -489 -471 -18 -50 51 -107 97 -80 8 4 121 127 253 273 131 146 326 362 434 480 108 118 199 221 204 228 32 53 -83 -46 -499 -430z"
                                      fill="#6e6e6e"
                                    />
                                    <path
                                      d="M937 1023 c-2 -5 -55 -200 -117 -435 -122 -461 -123 -469 -74 -527 64 -77 196 -56 232 36 6 16 64 226 128 467 64 242 118 446 121 453 4 10 -24 13 -140 13 -80 0 -148 -3 -150 -7z"
                                      fill="#6e6e6e"
                                    />
                                    <path
                                      d="M2040 1023 c0 -30 246 -925 261 -950 25 -40 93 -68 143 -59 46 9 92 53 105 102 10 37 1 76 -104 473 -63 238 -115 435 -115 437 0 2 -65 4 -145 4 -80 0 -145 -3 -145 -7z"
                                      fill="#6e6e6e"
                                    />
                                  </g>
                                </svg>
                                <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Page Management
                                </p>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="CreatePages"
                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")

                                  // }
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CreatePages"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Create Pages
                                  </span>
                                </NavLink>
                              </li>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="ViewPages"
                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")
                                  // }
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewPages"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View Pages
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
              ) : null}
              {role > 0 &&
                (role === 1 ||
                  role === 2 ||
                  role === 10 ||
                  role === 3 ||
                  role === 4 ||
                  role === 7 ||
                  role === 9 ||
                  role === 11 ||
                  role === 12 ||
                  role === 13 ||
                  role === 14 ||
                  role === 15) ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Membership")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          // className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150
                          // ${
                          //   (pathname === "/" ||
                          //     pathname.includes("Membership")) &&
                          //   "hover:theme-text-color"
                          // }`}
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/Membership/Pricing"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                        ${pathname === "/MembershipCampaign"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/Membership/MembershipTaskList"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/Membership/AuthorityLimit"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/Membership/MembershipFeeApproval"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/ViewCorporateMembers"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/IndividualMembers"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname ===
                              "/Membership/ChapterXCommitteeWorkgroupMapping"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/Membership/NotificationAnnouncement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <svg
                                className="menu-svg"
                                width="24"
                                height="24"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 60 60"
                              >
                                <path
                                  d="M48.014,42.889l-9.553-4.776C37.56,37.662,37,36.756,37,35.748v-3.381c0.229-0.28,0.47-0.599,0.719-0.951
                              c1.239-1.75,2.232-3.698,2.954-5.799C42.084,24.97,43,23.575,43,22v-4c0-0.963-0.36-1.896-1-2.625v-5.319
                              c0.056-0.55,0.276-3.824-2.092-6.525C37.854,1.188,34.521,0,30,0s-7.854,1.188-9.908,3.53C17.724,6.231,17.944,9.506,18,10.056
                              v5.319c-0.64,0.729-1,1.662-1,2.625v4c0,1.217,0.553,2.352,1.497,3.109c0.916,3.627,2.833,6.36,3.503,7.237v3.309
                              c0,0.968-0.528,1.856-1.377,2.32l-8.921,4.866C8.801,44.424,7,47.458,7,50.762V54c0,4.746,15.045,6,23,6s23-1.254,23-6v-3.043
                              C53,47.519,51.089,44.427,48.014,42.889z"
                                  fill="#6e6e6e"
                                />
                              </svg>
                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Membership
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {role === 1 ||
                              role === 2 ||
                              role === 12 ||
                              role === 14 ? (
                              <>
                                <li className=" my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/Pricing"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Membership/Pricing"
                                      ? "hover-active-color"
                                      : null
                                      }`}

                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")
                                  // }
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Pricing
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/MembershipCampaign"
                                    // className={({ isActive }) =>
                                    //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                    //   (isActive ? "!hover-active-color" : "")
                                    // }

                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/MembershipCampaign"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Campaign
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}

                            {role === 1 ||
                              role === 2 ||
                              role === 12 ||
                              role === 14 ||
                              role === 15 ?
                              <li className="mb-5 last:mb-0">
                                <NavLink
                                  end
                                  to="Membership/MembershipTaskList"
                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")
                                  // }
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                    "/Membership/MembershipTaskList"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Membership Task List
                                  </span>
                                </NavLink>
                              </li>
                              : null}

                            {role === 1 ||
                              role === 2 ||
                              role === 4 ||
                              role === 12 ||
                              role === 14 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/AuthorityLimit"
                                    // className={({ isActive }) =>
                                    //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                    //   (isActive ? "!hover-active-color" : "")
                                    // }
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "Membership/AuthorityLimit"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Authority Limit
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}

                            {role === 1 || role === 4 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/MembershipFeeApproval"
                                    // className={({ isActive }) =>
                                    //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                    //   (isActive ? "!hover-active-color" : "")
                                    // }
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "Membership/MembershipFeeApproval"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Fee Approval
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}

                            {role === 1 ||
                              role === 2 ||
                              role === 10 ||
                              role === 3 ||
                              role === 7 ||
                              role === 9 ||
                              role === 11 ||
                              role === 12 ||
                              role === 13 ||
                              role === 14 ||
                              role === 15 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/ViewCorporateMembers"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewCorporateMembers"
                                      ? "hover-active-color"
                                      : null
                                      }`}

                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")
                                  // }
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View Corporate Members
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/IndividualMembers"
                                    // className={({ isActive }) =>
                                    //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                    //   (isActive ? "!hover-active-color" : "")
                                    // }
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/IndividualMembers"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View Individual Members
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}

                            {role === 1 ||
                              role === 2 ||
                              role === 10 ||
                              role === 12 ||
                              role === 13 ||

                              role === 14 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/Membership/ChapterXCommitteeWorkgroupMapping"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/Membership/ChapterXCommitteeWorkgroupMapping"
                                      ? "hover-active-color"
                                      : null
                                      }`}

                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")
                                  // }
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Chapter x Committee Workgroup Mapping
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role !== 7 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/ChapterMemberReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ChapterMemberReport" ||
                                      pathname ===
                                      "/financial/ChapterMemberReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Chapter Members Report
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role !== 7 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/ChapterMemberCountTablesReports"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ChapterMemberCountTablesReports" ||
                                      pathname ===
                                      "/financial/ChapterMemberCountTablesReports"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Chapter Member Count Tables Reports
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role !== 7 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/RetentionReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/RetentionReport" ||
                                      pathname ===
                                      "/financial/RetentionReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Retention Reports
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role === 1 ||
                              role === 2 ||
                              role === 12 ||
                              role === 14 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/Membership/NotificationAnnouncement"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/Membership/NotificationAnnouncement"
                                      ? "hover-active-color"
                                      : null
                                      }`}

                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")
                                  // }
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Notification Announcement
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role === 1 ||
                              role === 2 ||
                              role === 12 ||
                              role === 14 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/Membership/MembershipDirectoryEnquiry"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/Membership/MembershipDirectoryEnquiry"
                                      ? "hover-active-color"
                                      : null
                                      }`}

                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")
                                  // }
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Directory Enquiry
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role === 10 || role === 1 ||
                              role === 2 ||
                              role === 12 ||
                              role === 14 ? (
                              <li className="mb-5 last:mb-0">
                                <NavLink
                                  end
                                  to="Membership/MembershipDirectoryReport"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                    "Membership/MembershipDirectoryReport"
                                    ? "hover-active-color"
                                    : null
                                    }`}

                                // className={({ isActive }) =>
                                //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                //   (isActive ? "!hover-active-color" : "")
                                // }
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Membership Directory Report
                                  </span>
                                </NavLink>
                              </li>
                            ) : null}

                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/* Dropdown-Menu End */}
              {/* Dropdown-Menu Start */}
              {role > 0 &&
                (role === 1 ||
                  role === 10 ||
                  role === 7 ||
                  role === 9 ||
                  role === 11 ||
                  role === 12 ||
                  role === 2 ||
                  role === 13 ||
                  role === 14) ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Entry_Management")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          // className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150
                          // ${
                          //   (pathname === "/" ||
                          //     pathname.includes("Entry_Management")) &&
                          //   "hover:theme-text-color"
                          // }`}
                          className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 
                        ${pathname === "/CreateEntry"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/ViewEditEntries"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                        ${pathname === "/MarketingTrackingURL"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/CreateEntry/eventId"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                            ${pathname === "/EventFeedbackQuestion"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                            ${pathname === "/FormQuestionAnswer"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                            
                        `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <svg
                                className="menu-svg"
                                width="24"
                                height="24"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 212.045 212.045"
                              >
                                <path
                                  d="M167.871,0H44.84C34.82,0,26.022,8.243,26.022,18v182c0,3.266,0.909,5.988,2.374,8.091c1.752,2.514,4.573,3.955,7.598,3.954
                              c2.86,0,5.905-1.273,8.717-3.675l55.044-46.735c1.7-1.452,4.142-2.284,6.681-2.284c2.538,0,4.975,0.832,6.68,2.288l54.86,46.724
                              c2.822,2.409,5.657,3.683,8.512,3.683c4.828,0,9.534-3.724,9.534-12.045V18C186.022,8.243,177.891,0,167.871,0z"
                                  fill="#6e6e6e"
                                />
                              </svg>
                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Entry Management
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {role === 1 ||
                              role === 2 ||
                              role === 10 ||
                              role === 9 ||
                              role === 11 ||
                              role === 12 ||
                              role === 13 ||
                              role === 14 ? (
                              <>
                                <li
                                  className="my-4 last:mb-0"
                                  onClick={() => window.location.reload()}
                                >
                                  <NavLink
                                    end
                                    to="/CreateEntry"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CreateEntry"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Create Entry
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/ViewEditEntries"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewEditEntries" ||
                                      pathname === "/CreateEntry/eventId"
                                      ? "hover-active-color"
                                      : null
                                      }`}

                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")
                                  // }
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View/Edit Entry
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/EventRegistrationApproval"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/EventRegistrationApproval" ||
                                      pathname ===
                                      "/financial/EventRegistrationApproval"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Event Registration Approval
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {/* <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="CategoryCampaignTagging"
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${
                                pathname ===
                                "/Entry_Management/Category&Campaign Tagging"
                                  ? "hover-active-color"
                                  : null
                              }`}

                              // className={({ isActive }) =>
                              //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                              //   (isActive ? "!hover-active-color" : "")
                              // }
                            >
                              <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Category & Campaign Tagging
                              </span>
                            </NavLink>
                          </li> */}
                            {role === 1 ||
                              role === 2 ||
                              role === 10 ||
                              role === 7 ||
                              role === 9 ||
                              role === 11 ||
                              role === 12 ||
                              role === 13 ||
                              role === 14 ? (
                              <li className="mb-5 last:mb-0">
                                <NavLink
                                  end
                                  to="/MarketingTrackingURL"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/MarketingTrackingURL"
                                    ? "hover-active-color"
                                    : null
                                    }`}

                                // className={({ isActive }) =>
                                //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                //   (isActive ? "!hover-active-color" : "")
                                // }
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Marketing Tracking URL
                                  </span>
                                </NavLink>
                              </li>
                            ) : null}
                            {role === 1 ||
                              role === 2 ||
                              role === 10 ||
                              role === 9 ||
                              role === 11 ||
                              role === 12 ||
                              role === 13 ||
                              role === 14 ? (
                              <>
                                <li
                                  className="my-4 last:mb-0"
                                // onClick={() => window.location.reload()}
                                >
                                  <NavLink
                                    end
                                    to="/EventFeedbackQuestion"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/EventFeedbackQuestion"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Event Feedback Question
                                    </span>
                                  </NavLink>
                                </li>
                                <li
                                  className="my-4 last:mb-0"
                                // onClick={() => window.location.reload()}
                                >
                                  <NavLink
                                    end
                                    to="/FormQuestionAnswer"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/FormQuestionAnswer"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Event Question Answer
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/* Dropdown-Menu End */}
              {/* Normal Menu Start*/}
              <li
                className={`custom-make-menu rounded-sm 2xl:mb-12 lg:mb-[40px] mb-[20px] last:mb-0 hidden ${pathname.includes("Over-the-Counter") && "bg-white"
                  }`}
              >
                <NavLink
                  end
                  to="/Over-the-Counter"
                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Over-the-Counter"
                    ? "hover-active-color"
                    : null
                    }`}

                // className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 ${
                //   pathname.includes("Over-the-Counter") &&
                //   "hover:theme-text-color"
                // }`}
                >
                  <div className="flex menu-icon-img">
                    <svg
                      className="menu-svg"
                      width="24"
                      height="24"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 488.488 488.488"
                    >
                      <g>
                        <g>
                          <path
                            d="M246.444,173.744c-38.3,0-69.3,31-69.3,69.3s31,69.3,69.3,69.3s69.3-31,69.3-69.3S284.744,173.744,246.444,173.744z
                            M266.344,270.344c-3,3.7-7,6.1-11.6,7.4c-2,0.5-2.9,1.6-2.8,3.7c0.1,2,0,4.1,0,6.1c0,1.8-0.9,2.8-2.7,2.8
                            c-2.2,0.1-4.4,0.1-6.6,0c-1.9,0-2.8-1.1-2.9-3c0-1.5,0-3,0-4.5c0-3.3-0.1-3.4-3.3-3.9c-4.1-0.6-8-1.6-11.8-3.4
                            c-2.9-1.4-3.2-2.1-2.4-5.2c0.6-2.3,1.3-4.6,2-6.8c0.8-2.6,1.5-2.9,3.9-1.7c4.1,2.1,8.5,3.3,13.1,3.9c3,0.4,5.8,0.1,8.6-1.1
                            c5.1-2.2,5.9-8.1,1.6-11.7c-1.5-1.2-3.1-2.1-4.9-2.9c-4.5-2-9.1-3.5-13.3-6c-6.8-4.1-11.2-9.7-10.7-18.1
                            c0.6-9.4,5.9-15.3,14.5-18.4c3.6-1.3,3.6-1.2,3.6-5c0-1.3,0-2.5,0-3.8c0.1-2.8,0.6-3.3,3.3-3.4c0.9,0,1.7,0,2.6,0c6,0,6,0,6,6
                            c0,4.2,0,4.2,4.2,4.9c3.2,0.5,6.3,1.5,9.3,2.8c1.6,0.7,2.3,1.9,1.8,3.6c-0.7,2.6-1.4,5.2-2.3,7.7c-0.8,2.4-1.5,2.8-3.9,1.6
                            c-4.6-2.2-9.5-3.2-14.7-2.9c-1.3,0.1-2.7,0.3-3.9,0.8c-4.4,1.9-5.2,6.8-1.4,9.9c1.9,1.5,4.1,2.6,6.4,3.6
                            c3.9,1.6,7.9,3.2,11.6,5.3C271.544,245.244,274.744,260.144,266.344,270.344z"
                            fill="#6e6e6e"
                          />
                        </g>
                        <g>
                          <circle
                            cx="347.244"
                            cy="246.544"
                            r="19.2"
                            fill="#6e6e6e"
                          />
                        </g>
                        <g>
                          <circle
                            cx="145.544"
                            cy="246.544"
                            r="19.2"
                            fill="#6e6e6e"
                          />
                        </g>
                        <g>
                          <path
                            d="M395.144,129.944h-301.8c-17.4,0-31.6,14.2-31.6,31.6v162.8c0,17.4,14.2,31.6,31.6,31.6h301.8
                            c17.4,0,31.6-14.2,31.6-31.6v-162.7C426.844,144.144,412.644,129.944,395.144,129.944z M399.844,294.044c-2.5-0.6-5.2-1-7.9-1
                            c-17.7,0-32,14.3-32,32c0,1.3,0.1,2.7,0.3,4h-234.4c0.4-1.9,0.6-3.9,0.6-5.9c0-17.7-14.3-32-32-32c-1.9,0-3.8,0.2-5.6,0.5v-97.2
                            c1.8,0.3,3.7,0.5,5.6,0.5c17.7,0,32-14.3,32-32c0-2-0.2-4-0.6-5.9h235c-0.6,2.5-1,5.1-1,7.8c0,17.7,14.3,32,32,32
                            c2.7,0,5.4-0.4,7.9-1v98.2H399.844z"
                            fill="#6e6e6e"
                          />
                        </g>
                        <g>
                          <path
                            d="M197.544,49.244l68,39.2c3.5,2,8-0.5,8-4.6v-21.5h42c27.2,0,49.3,22.1,49.3,49.3h35.4c0-46.7-38-84.8-84.8-84.8h-42
                          v-21.5c0-4.1-4.4-6.7-8-4.6l-68,39.2C194.044,42.044,194.044,47.144,197.544,49.244z"
                            fill="#6e6e6e"
                          />
                        </g>
                        <g>
                          <path
                            d="M290.944,439.244l-68-39.2c-3.5-2-8,0.5-8,4.6v21.5h-42c-27.2,0-49.3-22.1-49.3-49.3h-35.4c0,46.7,38,84.8,84.8,84.8h42
                          v21.5c0,4.1,4.4,6.7,8,4.6l68-39.2C294.444,446.444,294.444,441.344,290.944,439.244z"
                            fill="#6e6e6e"
                          />
                        </g>
                      </g>
                    </svg>
                    <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Over the Counter
                    </p>
                  </div>
                </NavLink>
              </li>
              {/* Normal Menu  End*/}
              {/* Dropdown-Menu Start */}
              <div className="hidden">
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" ||
                    pathname.includes("Council_Chapter&Committee")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 
                        ${(pathname === "/" ||
                              pathname.includes("Council_Chapter&Committee")) &&
                            "hover:theme-text-color"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <svg
                                className="menu-svg"
                                width="24"
                                height="24"
                                version="1.0"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 537 537"
                              >
                                <g
                                  transform="translate(0,537) scale(0.100000,-0.100000)"
                                  stroke="none"
                                >
                                  <path
                                    d="M684 4785 c-94 -20 -172 -63 -239 -130 -98 -98 -145 -210 -145 -345 0 -241 173 -441 416 -481 236 -38 478 122 539 356 30 117 14 254 -43 359 -38 71 -135 165 -205 199 -63 31 -171 57 -227 56 -19 0 -63 -7 -96 -14z"
                                    fill="#6e6e6e"
                                  />
                                  <path
                                    d="M4484 4785 c-94 -20 -172 -63 -239 -130 -98 -98 -145 -210 -145 -345 0 -241 173 -441 416 -481 236 -38 478 122 539 356 30 117 14 254 -43 359 -38 71 -135 165 -205 199 -63 31 -171 57 -227 56 -19 0 -63 -7 -96 -14z"
                                    fill="#6e6e6e"
                                  />
                                  <path
                                    d="M335 3735 c-130 -29 -231 -85 -280 -155 -57 -83 -57 -81 -50 -720 6 -552 8 -587 26 -626 46 -97 185 -138 273 -81 l23 16 5 -713 4 -712 28 -47 c15 -26 46 -60 68 -75 l41 -27 308 -3 c344 -3 347 -3 416 70 65 67 57 -141 78 2186 l5 552 453 0 c410 0 455 2 487 18 l35 17 3 137 c2 127 1 139 -18 158 -20 20 -28 20 -932 19 -717 -1 -926 -4 -973 -14z"
                                    fill="#6e6e6e"
                                  />
                                  <path
                                    d="M3130 3730 c-19 -19 -20 -31 -18 -158 l3 -137 35 -17 c32 -16 77 -18 487 -18 l453 0 5 -552 c21 -2327 13 -2119 78 -2186 69 -73 72 -73 416 -70 l308 3 41 27 c22 15 53 49 68 75 l28 47 4 712 5 713 23 -16 c88 -57 227 -16 273 81 19 39 20 68 23 646 3 601 3 605 -19 652 -41 89 -130 154 -270 195 -53 16 -138 18 -990 21 -929 2 -933 2 -953 -18z"
                                    fill="#6e6e6e"
                                  />
                                  <path
                                    d="M2616 2945 c-70 -47 -139 -143 -264 -366 l-82 -146 -133 -27 c-368 -76 -497 -138 -497 -238 0 -71 98 -216 279 -416 l103 -113 -17 -137 c-22 -167 -25 -412 -7 -463 7 -20 26 -49 43 -65 27 -26 37 -29 87 -28 57 2 161 32 257 74 102 46 142 63 212 96 40 19 77 34 83 34 6 0 43 -15 83 -34 331 -154 460 -191 536 -151 17 8 38 34 52 62 20 43 23 62 23 183 0 74 -8 201 -18 282 l-18 147 110 123 c321 358 344 461 126 551 -66 27 -309 89 -446 113 -36 6 -41 12 -112 138 -140 247 -217 351 -287 387 -50 25 -69 24 -113 -6z"
                                    fill="#6e6e6e"
                                  />
                                </g>
                              </svg>
                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Council, Chapter & Committee
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="/Council_Chapter&Committee/Create"
                                className={({ isActive }) =>
                                  "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  (isActive ? "!hover-active-color" : "")
                                }
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Create
                                </span>
                              </NavLink>
                            </li>
                            <li className="mb-5 last:mb-0">
                              <NavLink
                                end
                                to="/Council_Chapter&Committee/Manage Exco"
                                className={({ isActive }) =>
                                  "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  (isActive ? "!hover-active-color" : "")
                                }
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Manage Exco
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              </div>
              {/* Dropdown-Menu End */}

              {/* Dropdown-Menu Start */}
              {role > 0 &&
                (role === 1 ||
                  role === 8 ||
                  role === 2 ||
                  role === 10 ||
                  role === 3 ||
                  role === 4 ||
                  role === 7 ||
                  role === 9 ||
                  role === 11 ||
                  role === 12 ||
                  role === 13 ||
                  role === 14 ||
                  role === 16) ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Financial")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/financial/Accountpayable"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/financial/ViewBookingDetail"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/financial/GlobalSettingFinance"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/ChartsOfAccounts"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/PaymentReconciliation"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/CreditNoteApprovalProcess"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/CreditNoteManagement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/BatchJobExtraction"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                            ${pathname === "/financial/XeroInvoiceExtraction"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/CreditNoteApproval"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/AddEditExternalInvoice"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/ExternalInvoices"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                            ${pathname === "/financial/ExtractInvoices"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/AddEditExternalInvoice"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                            ${pathname ===
                              "/financial/ReconcilationUploadedData"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }                         
                        ${pathname === "/financial/PaymentReconciliation"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/VoucherCodeManagement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/CreateVoucherCode"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                            ${pathname === "/financial/MembershipReport"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                            ${pathname === "/financial/VoucherCodeReport"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                            ${pathname === "/financial/TransactionReport"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                            ${pathname === "/financial/OrganisationRoleReport"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/financial/DeferredIncomeReport"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/dtsme/DTSMEPurchaseReport"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            
                            
                        `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <svg
                                className="menu-svg"
                                width="24"
                                height="24"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 100"
                              >
                                <g transform="translate(0.0,100.0) scale(0.100000,-0.100000)">
                                  <path
                                    d="M433 963 c-8 -33 -13 -39 -48 -47 -35 -8 -40 -7 -60 18 l-21 27 -47 -26 c-58 -33 -59 -35 -46 -74 10 -28 8 -34 -16 -58 -24 -24 -29 -26 -57 -15 -38 14 -40 13 -73 -45 l-26 -47 25 -19 c19 -13 26 -28 26 -50 0 -43 -9 -53
                                -53 -61 l-38 -7 3 -57 c3 -57 3 -57 40 -66 33 -8 37 -13 43 -49 6 -35 4 -42 -20 -61 -27 -22 -27 -23 -11 -49 9 -15 23 -38 31 -51 13 -22 17 -23 50 -13 33 10 38 8 63 -16 24 -25 25 -30 15 -58 -15 -39 -14 -41 45 -74 l47 -26 21 27
                                c20 24 25 26 60 17 34 -7 40 -14 48 -46 l10 -37 58 0 c55 0 58 1 64 28 3 15 7 32 9 38 2 6 21 15 41 19 34 6 41 4 60 -20 l22 -27 46 27 c57 33 58 35 44 72 -10 26 -8 32 17 57 26 26 31 27 62 18 33 -10 35 -9 59 30 37 58 38 64 9 87
                                -24 18 -26 24 -18 57 7 30 15 40 43 49 34 11 35 14 38 68 3 55 2 57 -25 63 -15 3 -33 7 -39 9 -6 2 -15 20 -18 40 -6 31 -3 42 15 61 19 20 20 27 10 51 -6 15 -19 38 -29 51 -16 20 -20 21 -49 10 -30 -11 -35 -10 -62 16 -28 27 -29 29
                                -15 61 l14 34 -52 31 -52 32 -22 -27 c-20 -25 -25 -27 -60 -19 -31 8 -39 16 -49 44 -11 34 -14 35 -67 38 l-56 3 -9 -38z m169 -103 c351 -108 370 -580 28 -711 -224 -86 -461 54 -500 298 -18 111 24 238 108 322 103 103 226 134 364 91z"
                                    fill="#6e6e6e"
                                  />
                                  <path
                                    d="M400 749 c-182 -82 -217 -323 -67 -456 55 -47 116 -67 189 -61 272 23 329 404 78 517 -59 27 -141 27 -200 0z m140 -54 c0 -14 6 -28 13 -30 73 -26 70 -22 51 -54 -17 -28 -17 -28 -55 -13 -31 12 -44 13 -65 3 -36 -16 -27
                                -31 41 -68 70 -37 95 -65 95 -103 0 -32 -33 -78 -66 -90 -17 -7 -24 -17 -24 -35 0 -22 -4 -25 -35 -25 -30 0 -35 3 -35 23 0 19 -9 28 -40 40 -42 16 -48 30 -26 60 12 16 16 16 42 2 36 -19 74 -19 99 -1 27 19 13 44 -39 67 -73 32 -106
                                65 -106 105 0 37 36 84 65 84 10 0 15 10 15 30 0 28 3 30 35 30 31 0 35 -3 35 -25z"
                                    fill="#6e6e6e"
                                  />
                                </g>
                              </svg>
                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Financial
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>

                            {/* <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/Accountpayable"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Accountpayable"
                                        ? "hover-active-color"
                                        : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Account Payable
                                    </span>
                                  </NavLink>
                                </li> */}
                            {role === 1 || role === 3 || role === 16 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/BatchJobExtraction"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/BatchJobExtraction"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Batch Job Extraction
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role === 1 || role === 3  ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/XeroInvoiceExtraction"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/XeroInvoiceExtraction"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Xero Invoice Extraction
                                    </span>
                                  </NavLink>
                                </li>
                                {/*<li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/ViewBookingDetail/:bookingAttendeeInformationId/:bookingId"
                                    // className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${
                                    //   pathname ===
                                    //   "financial/ViewBookingDetail/:bookingAttendeeInformationId/:bookingId"
                                    //     ? "hover-active-color"
                                    //     : null
                                    // }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Booking Payment
                                    </span>
                                  </NavLink>
                                  </li>*/}

                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/ChartsOfAccounts"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ChartsOfAccounts"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Charts of Accounts
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role === 1 || role === 3 || role === 4 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/CreditNoteApproval"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CreditNoteApproval" ||
                                      pathname ===
                                      "/financial/CreditNoteManagement"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Credit Note
                                    </span>
                                  </NavLink>
                                </li>
                                {/* <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/CreditNoteApprovalProcess"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${
                                      pathname === "/CreditNoteApprovalProcess"
                                        ? "hover-active-color"
                                        : null
                                    }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Credit Note Approval Process
                                    </span>
                                  </NavLink>
                                </li> */}
                              </>
                            ) : null}
                            {role === 1 || role === 3 ? (
                              <>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/ExternalInvoices"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ExternalInvoices" ||
                                      pathname ===
                                      "/financial/AddEditExternalInvoice"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      External Invoices
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role === 1 || role === 3 || role === 16 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/ExtractInvoices"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ExtractInvoices" ||
                                      pathname === "/financial/ExtractInvoices"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Extract Invoices
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}

                            {role === 1 || role === 8 || role === 3 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/GlobalSettingFinance"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/GlobalSettingFinance"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Global Setting Finance
                                    </span>
                                  </NavLink>
                                </li>
                                {/*<li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="PageNotFound"
                                    className={({ isActive }) =>
                                      "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                      (isActive ? "!hover-active-color" : "")
                                    }
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Payment
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="PageNotFound"
                                    className={({ isActive }) =>
                                      "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                      (isActive ? "!hover-active-color" : "")
                                    }
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Payment Approval
                                    </span>
                                  </NavLink>
                                  </li>*/}
                              </>
                            ) : null}
                            {role === 1 || role === 3 || role === 16 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/PaymentReconciliation"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/PaymentReconciliation"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Payment Reconciliation
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {/*{role === 1 || role === 3 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/ReconcilationUploadedData"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ReconcilationUploadedData"
                                        ? "hover-active-color"
                                        : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Reconciliation Uploaded Excel
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                                  ) : null}*/}
                            {role === 1 ||
                              role === 2 ||
                              role === 10 ||
                              role === 3 ||
                              role === 4 ||
                              role === 7 ||
                              role === 9 ||
                              role === 11 ||
                              role === 12 ||
                              role === 13 ||
                              role === 14 ? (
                              <>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/VoucherCodeManagement"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/VoucherCodeManagement" ||
                                      pathname === "/CreateVoucherCode"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Voucher Code Management
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role !== 7 && role !== 16 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/MembershipReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/MembershipReport" ||
                                      pathname === "/financial/MembershipReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Report
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}

                            {/* voucher report start */}
                            {role !== 7 && role !== 16 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/VoucherCodeReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/VoucherCodeReport" ||
                                      pathname === "/financial/VoucherCodeReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Voucher Code Report
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {/* voucher report end */}

                            {/* AR Aging Report start */}
                            {role !== 7 && role !== 16 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/ARAgingReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ARAgingReport" ||
                                      pathname === "/financial/ARAgingReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      AR Aging Report
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {/* GST Report start */}
                            {role !== 7 && role !== 16 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/GSTReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/GSTReport" ||
                                      pathname === "/financial/GSTReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      GST Report
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}

                            {/* Transaction Report start */}
                            {role !== 7 && role !== 16  ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/TransactionReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/TransactionReport" ||
                                      pathname === "/financial/TransactionReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Transaction Report
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {/* Organisation Role report start */}
                            {role !== 7 && role !== 16  ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/OrganisationRoleReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/OrganisationRoleReport" ||
                                      pathname ===
                                      "/financial/OrganisationRoleReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Organisation Role Report
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {/* Deferred Income report start */}
                            {role !== 7 && role !== 16  ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/DeferredIncomeReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/DeferredIncomeReport" ||
                                      pathname === "/financial/DeferredIncomeReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Deferred Income Report
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {/* DT-SME Purchase report start */}
                            {role !== 7 && role !== 16  ? (
                              <>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="dtsme/DTSMEPurchaseReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/DTSMEPurchaseReport" ||
                                      pathname === "dtsme/DTSMEPurchaseReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      DT-SME Purchase Report
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/* Dropdown-Menu End */}
              {/* Normal Menu Start*/}
              {role > 0 &&
                (role === 1 ||
                  role === 2 ||
                  role === 10 ||
                  role === 9 ||
                  role === 11 ||
                  role === 12 ||
                  role === 13 ||
                  role === 14) ? (
                <li
                  className={`custom-make-menu rounded-sm 2xl:mb-12 lg:mb-[40px] mb-[20px] last:mb-0 ${pathname.includes("Partnerships") && "bg-white"
                    }`}
                >
                  <NavLink
                    end
                    to="/Partnerships"
                    className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 ${pathname.includes("Partnerships") &&
                      "hover:theme-text-color"
                      }`}
                  >
                    <div className="flex menu-icon-img">
                      <svg
                        className="menu-svg"
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <g
                          transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                          fill="#000000"
                          stroke="none"
                        >
                          <path
                            d="M330 784 c-36 -14 -87 -33 -113 -42 l-49 -18 1 -164 2 -165 61 -62 c57 -58 219 -159 233 -145 12 12 3 28 -25 44 -28 16 -42 48 -20 48 5 0 43 -16 84 -37 45 -22 80 -34 91 -29 24 9 10 23 -64 66 -58 33 -72 52 -52 64 5 4 43
                        -12 85 -35 78 -42 118 -47 124 -16 5 25 -10 41 -84 84 -60 35 -76 54 -55 68 5 3 54 -22 107 -55 54 -33 106 -60 115 -60 22 0 43 26 36 45 -4 8 -81 64 -172 124 l-166 110 -36 -16 c-52 -21 -154 -14 -190 13 -34 26 -53 82 -32 94 34 21
                        233 81 289 87 80 8 58 23 -35 22 -50 0 -89 -7 -135 -25z"
                            fill="#6e6e6e"
                          />
                          <path
                            d="M430 734 c-72 -19 -173 -54 -183 -64 -5 -4 4 -17 20 -29 23 -19 37 -22 88 -18 33 2 74 10 91 17 30 13 33 11 212 -109 l182 -121 0 139 0 140 -52 15 c-176 51 -255 57 -358 30z"
                            fill="#6e6e6e"
                          />
                          <path
                            d="M7 734 c-4 -4 -7 -76 -7 -160 0 -169 -2 -164 70 -164 20 0 43 6 49 14 8 9 11 61 9 162 l-3 149 -55 3 c-31 2 -59 0 -63 -4z"
                            fill="#6e6e6e"
                          />
                          <path
                            d="M875 688 c-9 -39 0 -287 12 -299 5 -5 32 -9 59 -7 l49 3 0 160 0 160 -57 3 c-53 3 -58 1 -63 -20z"
                            fill="#6e6e6e"
                          />
                        </g>
                      </svg>
                      <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Partnerships
                      </p>
                    </div>
                  </NavLink>
                </li>
              ) : null}
              {/* Normal Menu  End*/}
              {/* Dropdown-Menu Start */}
              <div className="hidden">
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Website-CMS")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          // className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150
                          // ${
                          //   (pathname === "#" ||
                          //     pathname.includes("Website-CMS")) &&
                          //   "hover:theme-text-color"
                          // }`}
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/FeaturedEvents"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                        ${pathname === "/CorporateMemberDirectory"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/ManageLeadershipPosition"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/Privileges"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/CommunitySpotlight"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <svg
                                className="menu-svg"
                                width="24"
                                height="24"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 92 92"
                              >
                                <path
                                  id="XMLID_1666_"
                                  d="M46,0C20.6,0,0,20.6,0,46s20.6,46,46,46s46-20.6,46-46S71.4,0,46,0z M49.7,83.8c-0.2,0-0.4,0-0.7,0.1V62.2 c5.2-0.1,9.9-0.2,14.2-0.5C59.4,73.4,52.3,81.2,49.7,83.8z M42.3,83.8c-2.7-2.7-9.7-10.5-13.5-22.1c4.2,0.3,9,0.5,14.2,0.5v21.7
                                C42.8,83.9,42.6,83.8,42.3,83.8z M8,46c0-2.5,0.3-5,0.7-7.4c2.2-0.4,6.4-1,12.3-1.6c-0.5,2.9-0.8,5.9-0.8,9.1c0,3.2,0.3,6.2,0.7,9 c-5.8-0.6-10.1-1.2-12.3-1.6C8.3,51,8,48.5,8,46z M26.3,46c0-3.4,0.4-6.6,1-9.6c4.6-0.3,9.8-0.6,15.7-0.6v20.4
                                c-5.8-0.1-11.1-0.3-15.8-0.7C26.7,52.6,26.3,49.4,26.3,46z M49.6,8.2c2.7,2.7,9.6,10.7,13.5,22.1c-4.2-0.3-8.9-0.5-14.1-0.5V8.1 C49.2,8.1,49.4,8.2,49.6,8.2z M43,8.1v21.7c-5.2,0.1-9.9,0.2-14.1,0.5c3.8-11.4,10.8-19.4,13.4-22.1C42.6,8.2,42.8,8.1,43,8.1z
                                M49,56.2V35.8c5.8,0.1,11.1,0.3,15.7,0.6c0.6,3,1,6.2,1,9.6c0,3.4-0.3,6.6-0.9,9.6C60.2,55.9,54.9,56.1,49,56.2z M70.9,37 c5.9,0.6,10.1,1.2,12.3,1.6C83.7,41,84,43.5,84,46c0,2.5-0.3,5-0.7,7.4c-2.2,0.4-6.4,1-12.3,1.6c0.5-2.9,0.7-5.9,0.7-9.1
                                C71.7,42.9,71.4,39.8,70.9,37z M81.4,32.2c-2.8-0.4-6.8-0.9-11.9-1.4c-2.4-8.6-6.6-15.5-10.1-20.4C69.5,14.2,77.5,22.2,81.4,32.2z M32.6,10.4c-3.6,4.8-7.7,11.7-10.1,20.3c-5,0.4-9,1-11.9,1.4C14.5,22.2,22.6,14.2,32.6,10.4z M10.6,59.8c2.8,0.4,6.8,0.9,11.8,1.4
                                c2.4,8.6,6.4,15.5,10,20.3C22.4,77.6,14.5,69.7,10.6,59.8z M59.6,81.5c3.6-4.8,7.6-11.6,10-20.2c5-0.4,9-1,11.8-1.4 C77.5,69.7,69.6,77.6,59.6,81.5z"
                                  fill="#6e6e6e"
                                />
                              </svg>

                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Website CMS
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="/FeaturedEvents"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/FeaturedEvents"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Featured Events
                                </span>
                              </NavLink>
                            </li>
                            <li className="mb-5 last:mb-0">
                              <NavLink
                                end
                                to="/CorporateMemberDirectory"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CorporateMemberDirectory"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Corporate Member Directory
                                </span>
                              </NavLink>
                            </li>
                            <li className="mb-5 last:mb-0">
                              <NavLink
                                end
                                to="/ManageLeadershipPosition"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ManageLeadershipPosition"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Manage Leadership Position
                                </span>
                              </NavLink>
                            </li>
                            <li className="mb-5 last:mb-0">
                              <NavLink
                                end
                                to="/Privileges"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Privileges"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Privileges
                                </span>
                              </NavLink>
                            </li>
                            <li className="mb-5 last:mb-0">
                              <NavLink
                                end
                                to="/CommunitySpotlight"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CommunitySpotlight"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Community Spotlight
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              </div>
              {/* Dropdown-Menu End */}
              {/* Dropdown-Menu Start */}
              {role > 0 &&
                (role === 1 ||
                  role === 7 ||
                  role === 9 ||
                  role === 10 ||
                  role === 11 ||
                  role === 2 ||
                  role === 13 ||
                  role === 14) ? (
                <>
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/" || pathname.includes("Resource")
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            // className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150
                            // ${
                            //   (pathname === "/Resource/" ||
                            //     pathname.includes("Resource")) &&
                            //   "hover:theme-text-color"
                            // }`}
                            className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/ViewResource"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }
                        ${pathname === "/CreatePreviewResource"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-basline justify-between">
                              <div className="flex menu-icon-img">
                                <svg
                                  className="menu-svg"
                                  width="24"
                                  height="24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 324 324"
                                >
                                  <g transform="translate(0,324) scale(0.100000,-0.100000)">
                                    <path
                                      d="M335 3216 c-48 -22 -77 -60 -82 -110 -7 -55 8 -95 48 -130 l29 -26 1305 0 1305 0 30 33 c55 60 62 127 20 187 -45 64 41 60 -1357 60 -1034 -1 -1274 -3 -1298 -14z"
                                      fill="#6e6e6e"
                                    />
                                    <path
                                      d="M320 2027 c0 -934 -2 -910 54 -877 34 20 536 481 536 491 0 5 17 20 37 33 20 14 80 68 134 121 85 82 102 95 132 95 41 0 70 -28 65 -64 -2 -16 -102 -134 -297 -348 l-295 -323 1090 -3 c749 -2 1101 0 1126 8 33 9 37 14 43
                                  53 3 23 5 407 3 852 l-3 810 -1312 3 -1313 2 0 -853z m2258 699 c15 -8 38 -26 52 -41 l25 -27 3 -566 c2 -535 1 -569 -16 -601 -36 -67 -132 -91 -199 -50 -65 40 -63 20 -63 640 0 545 1 563 20 594 36 58 119 82 178 51z m-436 -297 c9 -5
                                  30 -26 45 -46 l28 -36 0 -416 c0 -382 -1 -419 -18 -449 -28 -51 -72 -74 -135 -69 -46 3 -59 9 -90 41 l-37 36 -3 422 c-2 296 1 430 9 449 29 72 130 105 201 68z m-474 -209 c18 -11 41 -34 52 -52 19 -31 20 -51 20 -338 0 -287 -1 -307
                                  -20 -338 -62 -102 -204 -94 -251 13 -19 43 -20 64 -17 338 3 259 5 296 21 325 37 68 128 92 195 52z"
                                      fill="#6e6e6e"
                                    />
                                    <path
                                      d="M725 1398 c-296 -273 -483 -454 -489 -471 -18 -50 51 -107 97 -80 8 4 121 127 253 273 131 146 326 362 434 480 108 118 199 221 204 228 32 53 -83 -46 -499 -430z"
                                      fill="#6e6e6e"
                                    />
                                    <path
                                      d="M937 1023 c-2 -5 -55 -200 -117 -435 -122 -461 -123 -469 -74 -527 64 -77 196 -56 232 36 6 16 64 226 128 467 64 242 118 446 121 453 4 10 -24 13 -140 13 -80 0 -148 -3 -150 -7z"
                                      fill="#6e6e6e"
                                    />
                                    <path
                                      d="M2040 1023 c0 -30 246 -925 261 -950 25 -40 93 -68 143 -59 46 9 92 53 105 102 10 37 1 76 -104 473 -63 238 -115 435 -115 437 0 2 -65 4 -145 4 -80 0 -145 -3 -145 -7z"
                                      fill="#6e6e6e"
                                    />
                                  </g>
                                </svg>
                                <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Resources
                                </p>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="/ViewResource"
                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")
                                  // }
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewResource"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View Resources
                                  </span>
                                </NavLink>
                              </li>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="/CreatePreviewResource"
                                  // className={({ isActive }) =>
                                  //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                                  //   (isActive ? "!hover-active-color" : "")
                                  // }
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CreatePreviewResource"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Create Resources
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                  {/* Dropdown-Menu End */}
                  {/* Dropdown-Menu Start */}

                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/" || pathname.includes("BannerUpload")
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/bannerUpload/chapterscommittee"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }
                        ${pathname === "/bannerUpload/events"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }
                        ${pathname === "/bannerUpload/businessopportunities"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }
                        ${pathname === "/bannerUpload/articles"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              } 
                              ${pathname === "/bannerUpload/home"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }   
                        `}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-basline justify-between">
                              <div className="flex menu-icon-img">
                                <svg
                                  className="menu-svg"
                                  width="24"
                                  height="24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 131 131"
                                >
                                  <g transform="translate(0.000000,131.000000) scale(0.100000,-0.100000)">
                                    <path
                                      d="M63 1150 c-17 -10 -37 -28 -42 -39 -7 -13 -11 -168 -11 -461 0 -489 -2 -478 67 -506 49 -21 1108 -20 1158 0 65 28 65 26 65 513 0 481 2 466 -60 498 -25 13 -111 15 -587 15 -535 0 -559 -1 -590 -20z m1145 -68 c10 -7 12
                                  -100 10 -433 l-3 -424 -560 0 -560 0 -3 424 c-2 333 0 426 10 433 17 10 1089 10 1106 0z"
                                      fill="#6e6e6e"
                                    />
                                    <path
                                      d="M243 970 c-40 -24 -56 -54 -56 -105 0 -69 56 -125 124 -125 35 0 89 31 104 61 20 37 19 109 -1 137 -36 52 -115 67 -171 32z"
                                      fill="#6e6e6e"
                                    />
                                    <path
                                      d="M675 710 l-170 -170 -55 55 -56 55 -107 -108 -107 -109 0 -62 0 -61 473 2 472 3 3 144 3 144 -138 138 c-75 77 -140 139 -143 139 -3 0 -82 -76 -175 -170z"
                                      fill="#6e6e6e"
                                    />
                                  </g>
                                </svg>
                                <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Banner Management
                                </p>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li
                                className="my-4 last:mb-0"
                                onClick={() => window.location.reload()}
                              >
                                <NavLink
                                  end
                                  to="/bannerUpload/chapterscommittee"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                    "/bannerUpload/chapterscommittee"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Chapters/Committee
                                  </span>
                                </NavLink>
                              </li>
                              <li
                                className="my-4 last:mb-0"
                                onClick={() => window.location.reload()}
                              >
                                <NavLink
                                  end
                                  to="/bannerUpload/events"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/bannerUpload/events"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Events
                                  </span>
                                </NavLink>
                              </li>
                              <li
                                className="my-4 last:mb-0"
                                onClick={() => window.location.reload()}
                              >
                                <NavLink
                                  end
                                  to="/bannerUpload/businessopportunities"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                    "/bannerUpload/businessopportunities"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Business Opportunities
                                  </span>
                                </NavLink>
                              </li>
                              <li
                                className="my-4 last:mb-0"
                                onClick={() => window.location.reload()}
                              >
                                <NavLink
                                  end
                                  to="/bannerUpload/articles"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/bannerUpload/articles"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Articles
                                  </span>
                                </NavLink>
                              </li>
                              <li
                                className="my-4 last:mb-0"
                                onClick={() => window.location.reload()}
                              >
                                <NavLink
                                  end
                                  to="/bannerUpload/home"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/bannerUpload/home"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Home
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
              ) : null}
              {/* Dropdown-Menu End */}
              {/* Dropdown-Menu Start */}
              {role > 0 &&
                (role === 1 ||
                  role === 8 ||
                  role === 2 ||
                  role === 10 ||
                  role === 7 ||
                  role === 9 ||
                  role === 12 ||
                  role === 13 ||
                  role === 14) ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Field_Management")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/DropdownManagement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/EmailTemplateManagement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                        ${pathname === "/ManageTags"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                      `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <svg
                                className="menu-svg"
                                width="24"
                                height="24"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 100"
                              >
                                <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)">
                                  <path
                                    d="M187 890 c-5 -21 -41 -28 -52 -10 -11 18 -42 -8 -49 -40 -5 -26 -9 -30 -25 -25 -26 8 -48 -29 -31 -51 15 -17 6 -44 -15 -44 -23 0 -19 -47 5 -60 22 -12 27 -36 8 -42 -23 -8 6 -53 33 -53 19 0 25 -6 27 -27 4 -31 35 -46 57
                                  -28 11 9 18 7 34 -9 24 -24 61 -28 61 -7 0 17 35 27 48 14 17 -17 55 1 49 23 -6 21 17 44 36 36 22 -8 39 29 25 54 -11 18 -10 23 10 36 25 18 31 63 7 63 -21 0 -30 27 -16 44 16 19 -7 58 -30 51 -19 -7 -41 17 -34 36 3 8 -5 20 -17
                                  28 -16 10 -23 10 -26 2 -6 -18 -40 -13 -52 9 -6 11 -19 20 -29 20 -10 0 -21 -9 -24 -20z m65 -40 c145 -41 169 -233 37 -301 -52 -26 -126 -22 -167 8 -37 27 -72 91 -72 131 0 82 74 165 155 171 6 1 27 -4 47 -9z"
                                    fill="#6e6e6e"
                                  />
                                  <path
                                    d="M162 798 c-65 -32 -80 -133 -27 -188 88 -92 236 16 181 132 -28 58 -96 83 -154 56z"
                                    fill="#6e6e6e"
                                  />
                                  <path
                                    d="M710 851 c0 -25 -40 -35 -56 -14 -11 16 -14 16 -38 -1 -19 -12 -25 -23 -21 -35 7 -22 -19 -46 -40 -37 -9 3 -21 -4 -31 -20 -15 -21 -15 -27 -2 -42 19 -22 12 -52 -13 -52 -27 0 -27 -60 0 -67 26 -7 32 -33 13 -55 -13 -15
                                  -13 -21 2 -42 10 -16 22 -23 31 -20 21 8 47 -15 40 -36 -4 -12 4 -23 21 -35 25 -16 28 -16 39 -2 15 21 55 11 55 -14 0 -28 60 -27 67 0 6 25 33 32 55 13 14 -12 20 -11 42 3 14 10 23 22 19 31 -7 20 17 46 38 41 12 -3 24 4 34 20 14
                                  21 15 27 3 41 -19 22 -12 49 13 55 27 7 28 67 0 67 -24 0 -36 41 -17 55 22 16 -7 65 -33 58 -27 -7 -52 18 -40 39 6 12 2 20 -17 33 -22 14 -28 15 -42 3 -22 -19 -49 -12 -55 13 -7 27 -67 28 -67 0z m119 -66 c146 -74 145 -272 -2 -341
                                  -65 -30 -110 -30 -167 -1 -146 73 -143 281 5 348 55 25 107 24 164 -6z"
                                    fill="#6e6e6e"
                                  />
                                  <path
                                    d="M706 750 c-110 -34 -130 -191 -31 -256 37 -24 106 -22 143 4 34 25 62 77 62 117 0 88 -92 160 -174 135z"
                                    fill="#6e6e6e"
                                  />
                                  <path
                                    d="M334 389 c-3 -6 -16 -8 -28 -5 -23 6 -55 -9 -43 -20 9 -9 -12 -36 -23 -29 -4 3 -13 -2 -20 -10 -10 -12 -10 -18 0 -30 10 -12 9 -16 -4 -21 -20 -8 -21 -38 -1 -54 12 -10 13 -16 4 -26 -9 -11 -8 -18 1 -29 7 -9 15 -13 18
                                  -10 7 7 33 -16 27 -25 -3 -4 2 -13 10 -20 12 -10 18 -10 29 0 12 9 17 7 28 -9 17 -27 32 -27 51 1 12 18 18 20 25 10 11 -16 45 -4 38 14 -6 15 17 37 28 27 12 -12 26 20 20 44 -4 16 -1 25 10 29 21 8 21 33 0 41 -10 4 -14 13 -10 27 6
                                  23 -9 55 -20 43 -10 -10 -35 13 -28 25 9 15 -17 28 -43 21 -14 -3 -23 -1 -23 6 0 14 -37 14 -46 0z m75 -37 c35 -19 71 -72 71 -105 0 -41 -32 -86 -76 -107 -38 -19 -49 -20 -79 -10 -50 16 -85 62 -85 111 0 53 22 92 62 112 43 21 66 21 107 -1z"
                                    fill="#6e6e6e"
                                  />
                                  <path
                                    d="M295 298 c-31 -37 -29 -77 7 -115 61 -65 163 13 128 98 -25 59 -92 68 -135 17z"
                                    fill="#6e6e6e"
                                  />
                                </g>
                              </svg>
                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Field Maintenance
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {role > 0 && (role === 1 || role === 8) ? (
                              <>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="/DropdownManagement"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/DropdownManagement"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Dropdown Management
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role > 0 &&
                              (role === 1 ||
                                role === 8 ||
                                role === 7 ||
                                role === 14) ? (
                              <>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="/ManageTags"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ManageTags"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Manage Tags
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role > 0 &&
                              (role === 1 ||
                                role === 8 ||
                                role === 2 ||
                                role === 10 ||
                                role === 7 ||
                                role === 9 ||
                                role === 12 ||
                                role === 13 ||
                                role === 14) ? (
                              <>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="/EmailTemplateManagement"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/EmailTemplateManagement"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Email Template Management
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/* Dropdown-Menu End */}
              {/* Dropdown-Menu Start */}
              <div className="hidden">
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Field_Management")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  ${pathname === "/EmailHistory"
                            ? "active hover-active-color"
                            : "hover:theme-text-color"
                            }  ${pathname === "/CreateEmail"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <svg
                                className="menu-svg"
                                width="24"
                                height="24"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 330 330"
                              >
                                <path
                                  id="XMLID_350_"
                                  d="M173.871,177.097c-2.641,1.936-5.756,2.903-8.87,2.903c-3.116,0-6.23-0.967-8.871-2.903L30,84.602
                                L0.001,62.603L0,275.001c0.001,8.284,6.716,15,15,15L315.001,290c8.285,0,15-6.716,15-14.999V62.602l-30.001,22L173.871,177.097z"
                                  fill="#6e6e6e"
                                />
                                <polygon
                                  points="165.001,146.4 310.087,40.001 19.911,40"
                                  fill="#6e6e6e"
                                />
                              </svg>

                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Email Management
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="/EmailHistory"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/EmailHistory"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Email History
                                </span>
                              </NavLink>
                            </li>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="/CreateEmail"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CreateEmail"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Create Email
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              </div>
              {/* Dropdown-Menu End */}

              {/* {/ Dropdown-Menu Start /} */}
              {role > 0 &&
                (role === 1 ||
                  role === 2 ||
                  role === 10 ||
                  role === 9 ||
                  role === 12 ||
                  role === 11 ||
                  role === 13 ||
                  role === 14) ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Field_Management")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/booking/ViewNewBookings"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/booking/ViewBookingCancellation"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/ViewBookingDetail"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                        
                      `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <svg
                                className="menu-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 833 833"
                              >
                                <g
                                  transform="translate(0.000000,833.000000) scale(0.100000,-0.100000)"
                                  stroke="none"
                                >
                                  <path
                                    d="M2475 8322 c-137 -12 -261 -89 -328 -203 -48 -82 -57 -142 -57 -396
                                    l0 -232 -507 -4 c-441 -3 -518 -6 -583 -21 -249 -59 -458 -173 -627 -343 -184
                                    -185 -294 -396 -350 -666 -15 -75 -17 -292 -20 -2617 -3 -1731 -1 -2576 7
                                    -2665 27 -337 139 -584 365 -810 179 -180 366 -283 620 -341 77 -18 196 -19
                                    3175 -19 2979 0 3098 1 3175 19 254 58 441 161 620 341 174 173 269 340 337
                                    595 l23 85 3 2650 c2 1921 0 2672 -8 2729 -64 450 -391 846 -821 995 -184 64
                                    -187 64 -741 68 l-508 4 0 232 c0 254 -9 314 -57 396 -164 280 -586 266 -724
                                    -25 -41 -85 -49 -148 -49 -391 l0 -213 -1250 0 -1250 0 0 213 c0 116 -4 238
                                    -10 271 -24 150 -138 286 -272 327 -45 14 -127 25 -163 21z m-385 -1894 c0
                                    -254 7 -297 56 -388 40 -73 95 -125 178 -165 192 -95 424 -27 529 153 57 98
                                    60 116 64 385 l5 247 1248 0 1248 0 5 -247 c4 -269 7 -287 64 -385 105 -180
                                    337 -248 529 -153 83 40 138 92 178 165 49 91 56 134 56 388 l0 233 468 -3
                                    467 -3 75 -36 c92 -45 160 -111 203 -199 l32 -65 3 -682 3 -683 -3331 0 -3331
                                    0 3 683 3 682 32 65 c53 108 148 188 268 226 30 9 156 13 493 13 l452 1 0
                                    -232z m5408 -3780 l-3 -1513 -31 -65 c-41 -87 -116 -162 -201 -203 l-68 -32
                                    -3025 0 -3025 0 -68 32 c-85 41 -160 116 -201 203 l-31 65 -3 1513 -2 1512
                                    3330 0 3330 0 -2 -1512z"
                                  />
                                  <path
                                    d="M4921 3608 c-35 -23 -57 -54 -389 -548 -102 -151 -200 -295 -217
                                    -320 -37 -53 -256 -376 -367 -541 l-78 -116 -237 237 c-131 131 -248 242 -260
                                    249 -30 15 -89 14 -121 -3 -42 -23 -230 -221 -238 -250 -3 -15 -4 -44 0 -64 5
                                    -32 54 -85 393 -424 213 -214 404 -396 423 -406 22 -10 58 -17 95 -17 93 0
                                    119 25 279 265 31 47 79 119 107 160 187 274 416 612 567 835 97 143 208 307
                                    246 364 39 57 99 147 134 199 99 152 F90 183 -97 311 -143 97 -183 109 -240 69z"
                                  />
                                </g>
                              </svg>

                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Booking
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {role > 0 &&
                              (role === 1 ||
                                role === 2 ||
                                role === 10 ||
                                role === 9 ||
                                role === 12 ||
                                role === 11 ||
                                role === 13 ||
                                role === 14) ? (
                              <>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="booking/ViewNewBookings"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/booking/ViewNewBookings" ||
                                      pathname ===
                                      "/financial/ViewBookingDetail"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View New Bookings
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="booking/ViewBookingCancellation"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/booking/ViewBookingCancellation"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View New Booking Cancellation
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/* {/ Dropdown-Menu End /} */}
              {/* Normal Menu Start*/}
              {role > 0 && (role === 1 || role === 7 || role === 14) ? (
                <>
                  <li
                    className={`custom-make-menu rounded-sm 2xl:mb-12 lg:mb-[40px] mb-[20px] last:mb-0 ${pathname.includes("SGTech_TV") && "bg-white"
                      }`}
                  >
                    <NavLink
                      end
                      to="/SGTechTv"
                      // className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 ${
                      //   pathname.includes("Over-the-Counter") &&
                      //   "hover:theme-text-color"
                      // }`}
                      className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/SGTech_TV"
                          ? "active hover-active-color"
                          : "hover:theme-text-color"
                        }  
                        `}
                    >
                      <div className="flex menu-icon-img">
                        <svg
                          className="menu-svg"
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 200 200"
                        >
                          <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)">
                            <path
                              d="M210 1901 c-83 -26 -141 -74 -181 -151 l-24 -45 -3 -490 c-2 -337 0 -506 8 -542 15 -70 61 -135 123 -175 l52 -33 793 -3 c518 -2 806 1 831 8 64 17 129 75 162 144 l29 61 0 502 c0 477 -1 505 -20 556 -22 60 -73 113 -140
                        148 l-45 24 -780 2 c-429 1 -791 -2 -805 -6z m1560 -186 c16 -8 34 -24 40 -34 6 -13 10 -188 10 -496 0 -502 -2 -522 -45 -539 -9 -3 -360 -6 -780 -6 l-763 0 -26 31 -26 31 0 473 c0 276 4 484 10 499 21 57 -1 55 808 56 644 0 747 -2 772 -15z"
                              fill="#6e6e6e"
                            />
                            <path
                              d="M503 260 c-28 -11 -43 -40 -43 -83 0 -27 7 -41 31 -61 l31 -26 478 0 478 0 31 26 c41 34 44 92 6 129 l-24 25 -483 -1 c-266 0 -493 -4 -505 -9z"
                              fill="#6e6e6e"
                            />
                          </g>
                        </svg>
                        <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          SGTech TV
                        </p>
                      </div>
                    </NavLink>
                  </li>
                </>
              ) : null}

              {/* Dropdown-Menu Start */}
              {role > 0 &&
                (role === 1 || role === 8 || role === 11 || role === 13) ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Field_Management")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/goGlobal/GoGlobalContactRequest"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/goGlobal/GoGlobalAccountApproval"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                        ${pathname === "/goGlobal/ClickAnalytics"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                      `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <FieldMaintenanceIcon />
                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Go Global
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="goGlobal/GoGlobalContactRequest"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "goGlobal/GoGlobalContactRequest"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Go Global Contact Request
                                </span>
                              </NavLink>
                            </li>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="goGlobal/GoGlobalAccountApproval"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                  "goGlobal/GoGlobalAccountApproval"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Go Global Account Approval
                                </span>
                              </NavLink>
                            </li>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="goGlobal/ClickAnalytics"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "goGlobal/ClickAnalytics"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Click Analytics
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/* Dropdown-Menu End */}

              {/* DT-SME Dropdown-Menu Start */}
              {/* Dropdown-Menu Start */}
              {role > 0 &&
                (role === 1 || role === 8 || role === 11 || role === 13) ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Field_Management")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/dtsme/MarketPlace"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/dtsme/PartnerConsultants"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                        ${pathname === "/dtsme/SolutionProviders"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                            ${pathname === "/dtsme/DTSMEMentors"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                      `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <FieldMaintenanceIcon />
                              <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                DT-SME
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {/* <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="dtsme/MarketPlace"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "dtsme/MarketPlace"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Market Place
                                </span>
                              </NavLink>
                            </li> */}
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="dtsme/PartnerConsultants"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                  "dtsme/PartnerConsultants"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Partner Consultants
                                </span>
                              </NavLink>
                            </li>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="dtsme/SolutionProviders"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "dtsme/SolutionProviders"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Solution Providers
                                </span>
                              </NavLink>
                            </li>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="dtsme/DTSMEMentors"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "dtsme/DTSMEMentors"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  DT-SME Mentors
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/* Dropdown-Menu End */}
              {/* Normal Menu Start*/}
              {/* {role > 0 &&
                (role === 1 ||
                  role === 2 ||
                  role === 10 ||
                  role === 9 ||
                  role === 11 ||
                  role === 12 ||
                  role === 13 ||
                  role === 14) ? (
                <li
                  className={`custom-make-menu rounded-sm 2xl:mb-12 lg:mb-[40px] mb-[20px] last:mb-0 ${pathname.includes("DTSME") && "bg-white"
                    }`}
                >
                  <NavLink
                    end
                    to="/DTSME"
                    className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 ${pathname.includes("Partnerships") &&
                      "hover:theme-text-color"
                      }`}
                  >
                    <div className="flex menu-icon-img">
                      <FieldMaintenanceIcon />
                      <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        DT-SME
                      </p>
                    </div>
                  </NavLink>
                </li>
              ) : null} */}
              {/* Normal Menu  End*/}
              {/*DT-SME Dropdown-Menu End */}

              {/* Publication Report Start */}
              {/* Normal Menu Start*/}
              {role > 0 &&
                (role === 1 ||
                  role === 2 ||
                  role === 10 ||
                  role === 9 ||
                  role === 11 ||
                  role === 12 ||
                  role === 13 ||
                  role === 14) ? (
                <li
                  className={`custom-make-menu rounded-sm 2xl:mb-12 lg:mb-[40px] mb-[20px] last:mb-0 ${pathname.includes("PublicationReport") && "bg-white"
                    }`}
                >
                  <NavLink
                    end
                    to="/PublicationReport"
                    className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 ${pathname.includes("Partnerships") &&
                      "hover:theme-text-color"
                      }`}
                  >
                    <div className="flex menu-icon-img">
                      <FieldMaintenanceIcon />
                      <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Reports
                      </p>
                    </div>
                  </NavLink>
                </li>
              ) : null}
              {/* Normal Menu  End*/}
              {/*Publication Report End */}


              {/* Super Menu Start */}
              {IsSuperReportDisable ? null :

                role > 0 &&
                  (role === 1 ||
                    role === 2 ||
                    role === 10 ||
                    role === 9 ||
                    role === 12 ||
                    role === 11 ||
                    role === 13 ||
                    role === 14) ? (
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/" || pathname.includes("Field_Management")
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/superReport/CreateReport"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }
                        ${pathname === "/superReport/RunReport"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }   
                      `}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-basline justify-between">
                              <div className="flex menu-icon-img">
                                <FieldMaintenanceIcon />
                                <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Super Report
                                </p>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {role > 0 &&
                                (role === 1 ||
                                  role === 2 ||
                                  role === 10 ||
                                  role === 9 ||
                                  role === 12 ||
                                  role === 11 ||
                                  role === 13 ||
                                  role === 14) ? (
                                <>
                                  <li className="my-4 last:mb-0">
                                    <NavLink
                                      end
                                      to="superReport/CreateReport"
                                      className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/superReport/CreateReport" ||
                                        pathname ===
                                        "/superReport/CreateReport"
                                        ? "hover-active-color"
                                        : null
                                        }`}
                                    >
                                      <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        Create Report
                                      </span>
                                    </NavLink>
                                  </li>
                                  <li className="my-4 last:mb-0">
                                    <NavLink
                                      end
                                      to="superReport/RunReport"
                                      className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                        "/superReport/RunReport"
                                        ? "hover-active-color"
                                        : null
                                        }`}
                                    >
                                      <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        Run Report
                                      </span>
                                    </NavLink>
                                  </li>
                                </>
                              ) : null}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                ) : null
              }
              {/* Super Menu End */}


              {/* Normal Menu  End*/}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
