import Dashboard from "../pages/dashboard/Dashboard";
import CreatePages from "../pages/pagesmanagement/CreatePages";
import FeaturedEvents from "../pages/websiteCms/featuredEvents/FeaturedEvents";
import CorporateMemberDirectory from "../pages/websiteCms/corporateMemberDirectory/CorporateMemberDirectory";
import ManageLeadershipPosition from "../pages/websiteCms/manageLeadershipPosition/ManageLeadershipPosition";
import AddEditLeadershipPosition from "../pages/websiteCms/manageLeadershipPosition/AddEditLeadershipPosition";
import Privileges from "../pages/websiteCms/privileges/Privileges";
import CreatePrivilegesPost from "../pages/websiteCms/privileges/CreatePrivilegesPost ";
import ViewResource from "../pages/resources/ViewResource";
import CreateResources from "../pages/resources/CreateResources";
import CommunitySpotlight from "../pages/websiteCms/communitySpotlight/CommunitySpotlight";
import SGTechTv from "../pages/sgTechTv/SGTechTv";
import UploadSGTechTV from "../pages/sgTechTv/UploadSGTechTV";
import BannerUpload from "../pages/bannermanagement/BannerUpload";
import PostApproval from "../pages/resources/PostApproval";
import PreviewResources from "../pages/resources/PreviewResources";
import CreatePreviewResource from "../pages/resources/CreatePreviewResource";
import EditResources from "../pages/resources/EditResources";
import MembershipPricing from "../pages/membership/membershipPricing/MembershipPricing";
import MembershipCampaign from "../pages/membership/membershipCampaign/MembershipCampaign";
import AddEditMembershipCampaign from "../pages/membership/membershipCampaign/membershipAddEditCompaign/AddEditMembershipCampaign";
import ViewPages from "../pages/pagesmanagement/ViewPages";
import AuthorityLimit from "../pages/membership/AuthorityLimit";
import NotificationAnnouncemnet from "../pages/membership/NotificationAnnouncement";
import MembershipTaskList from "../pages/membership/membershipTaskList/MembershipTaskList";
import MembershipFeeApproval from "../pages/membership/membershipFeeApproval/MembershipFeeApproval";
import ChapterXCommitteeWorkgroupMapping from "../pages/membership/chapterXCommitteeWorkgroupMapping/ChapterXCommitteeWorkgroupMapping";
import EditPages from "../pages/pagesmanagement/EditPages";
import ViewCorporateMembers from "../pages/membership/corporateMembers/ViewCorporateMembers";
import CreateCorporateMembers from "../pages/membership/corporateMembers/CreateCorporateMembers";
import IndividualMembers from "../pages/membership/individualMembers/IndivisualMembers";
import CreateIndividualMembers from "../pages/membership/individualMembers/CreateIndividualMembers";
import MarketingTrackingURL from "../pages/entrymanagement/MarketingTrackingURL";
// import AccaTimePicker from '../components/UIComponent/TimePicker/TimePicker';
import ViewEditEntries from "../pages/entrymanagement/ViewEditEntries";
import CreateEntry from "../pages/entrymanagement/CreateEntry";
import CategoryCampaignTagging from "../pages/entrymanagement/CategoryCampaignTagging";
import Partnerships from "../pages/partnerships/Partnerships";
import ProjectReview from "../pages/partnerships/ProjectReview";
import AddEditGovernmentPartner from "../pages/partnerships/AddEditGovernmentPartner";
// import SGTechTv2 from '../pages/sgTechTv/SGTechTv2';
import CreateIndividualMembersNew from "../pages/membership/individualMembers/CreateIndividualMembersNew";
import AddEditStrategicMember from "../pages/partnerships/AddEditStrategicMember";
import AddEditProject from "../pages/partnerships/AddEditProject";
import CreateAdminAccount from "../pages/accountManagement/CreateAdminAccount";
import ViewEditAdminAccount from "../pages/accountManagement/ViewEditAdminAccount";
import DropdownManagement from "../pages/fieldManagement/DropdownManagement";
import EmailTemplateManagement from "../pages/fieldManagement/EmailTemplateManagement";
import EmailHistory from "../pages/emailManagement/EmailHistory";
import CreateEmail from "../pages/emailManagement/CreateEmail";
import ManagePages from "../pages/pagesmanagement/ManagePages";
import ChaptersCommitteePrieview from "../components/bannerManagement/ChaptersCommitteePrieview";
import BannerPreview from "../pages/bannermanagement/BannerPreview";
import MobileBannerPreview from "../components/bannerManagement/MobileBannerPreview";
import MembershipDirectoryEnquiry from "../pages/membership/membershipDirectoryEnquiry/MembershipDirectoryEnquiry";
import ManageTags from "../pages/fieldManagement/ManageTags";
import CreateEmailTemplate from "../components/fieldManagement/CreateEmailTemplate";

// Financial Start
import GlobalSettingFinance from "../pages/financial/GlobalSettingFinance";
import ChartsOfAccounts from "../pages/financial/ChartsOfAccounts";
import PaymentReconcilation from "../pages/financial/PaymentReconcilation";
// import CreditNoteApprovalProcess from "../pages/financial/CreditNoteApprovalProcess";
import BatchJobExtraction from "../pages/financial/BatchJobExtraction";
import CreditNoteApproval from "../pages/financial/CreditNoteApproval";
import AddEditExternalInvoice from "../pages/financial/AddEditExternalInvoice";
import CreditNoteManagement from "../pages/financial/CreditNoteManagement";
import ExternalInvoices from "../pages/financial/ExternalInvoices";
import ViewBookingDetail from "../pages/financial/ViewBookingDetail";
// import Accountpayable from "../pages/financial/Accountpayable";
import ViewBookingCancellation from "../pages/booking/ViewBookingCancellation";
import ViewNewBookings from "../pages/booking/ViewNewBookings";
import NewBookingCancellationDetail from "../pages/booking/NewBookingCancellationDetail";
import UnAuthorized from "../pages/authentication/UnAuthorized";
import PageNotFound from "../pages/pageNotFound/PageNotFound";
import TestEditorPage from "../pages/TestEditorPage/TestEditorPage";
import VoucherCodeManagement from "../pages/financial/VoucherCodeManagement";
import CreateVoucherCode from "../pages/financial/CreateVoucherCode";
import EventRegistrationApproval from "../pages/financial/EventRegistrationApproval";
import ExtractInvoices from "../pages/financial/ExtractInvoices";
import MembershipDirectoryReport from "../pages/membership/MembershipDirectoryReport"



// Go Global Start
import GoGlobalContactRequest from "../pages/goGlobal/GoGlobalContactRequest";
import GoGlobalAccountApproval from "../pages/goGlobal/GoGlobalAccountApproval";
import ClickAnalytics from "../pages/goGlobal/ClickAnalytics";
import ViewGoGlobalAccount from "../pages/goGlobal/ViewGoGlobalAccount";
import EventFeedbackQuestion from "../pages/entrymanagement/EventFeedbackQuestion";
import MembershipReport from "../pages/financial/MembershipReport";
import ARAgingReport from "../pages/financial/ARAgingReport";
import GSTReport from "../pages/financial/GSTReport";
import FormQuestionAnswer from "../pages/entrymanagement/FormQuestionAnswer";
import VoucherCodeReport from "../pages/financial/VoucherCodeReport";
import TransactionReport from "../pages/financial/TransactionReport";
import OrganisationRoleReport from "../pages/financial/OrganisationRoleReport";
import DeferredIncomeReport from "../pages/financial/DeferredIncomeReport";

// DTSME
import DTSME from "../pages/dtsme/DTSME";
import AddEditDTSMEMember from "../pages/dtsme/AddEditDTSMEMember";
import XeroInvoiceExtraction from "../pages/financial/XeroInvoiceExtraction";
import ReconcilationUploadedData from "../pages/financial/ReconcilationUploadedData";
import DTSMEPurchaseReport from "../pages/dtsme/DTSMEPurchaseReport";


//publication Report 
import PublicationReport from "../pages/publicationReport/GenAICyberConnectReport"
import CreateReport from "../pages/suportReport/CreateReport";
import RunReport from "../pages/suportReport/RunReport";
import MarketPlace from "../pages/dtsme/MarketPlace";
import PartnerConsultants from "../pages/dtsme/PartnerConsultants";
import SolutionProviders from "../pages/dtsme/SolutionProviders";
import DTSMEMentors from "../pages/dtsme/DTSMEMentors";
import AddEditPartnerConsultants from "../pages/dtsme/AddEditPartnerConsultant";
import AddEditSolutionProviders from "../pages/dtsme/AddEditSolutionProviders";
import AddEditDTSMEMentors from "../pages/dtsme/AddEditDTSMEMentors";
import ChapterMemberReport from "../pages/financial/ChapterMemberReport";
import ChapterMemberCountTablesReports from "../pages/financial/ChapterMemberCountTablesReports";
import RetentionReport from "../pages/financial/RetentionReport";

const ComponentNavigation = [
  // {
  //   path: "/",
  //   title: "Dashboard",
  //   component: Dashboard,
  //   hasParams: false,
  //   roles: [1,1]
  // },
  {
    path: "Dashboard",
    title: "Dashboard",
    component: Dashboard,
    hasParams: false,
    roles: [1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14, 6, 15, 16]
  },
  {
    path: "PageNotFound",
    title: "PageNotFound",
    component: PageNotFound,
    hasParams: false,
    roles: [1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14]
  },
  {
    path: "CorporateMemberDirectory",
    title: "Corporate Member Directory",
    previousPath: "Website CMS",
    component: CorporateMemberDirectory,
    hasParams: false,
    roles: [1, 7, 14]
  },
  {
    path: "ManageLeadershipPosition",
    title: "Manage Leadership Position",
    previousPath: "Website CMS",
    component: ManageLeadershipPosition,
    hasParams: false,
    roles: [1, 7, 14]
  },
  {
    path: "AddEditLeadershipPosition",
    title: "Edit Leadership Position",
    component: AddEditLeadershipPosition,
    hasParams: false,
    roles: [1, 7, 14]
  },
  {
    path: "AddEditLeadershipPosition/:leadershipPositionId",
    title: "Edit Leadership Position",
    component: AddEditLeadershipPosition,
    hasParams: true,
    roles: [1, 7, 14]
  },
  {
    path: "Privileges",
    title: "Privileges",
    previousPath: "Website CMS",
    component: Privileges,
    hasParams: false,
    roles: [1, 7, 14]
  },
  {
    path: "FeaturedEvents",
    title: "Featured Events",
    previousPath: "Website CMS",
    component: FeaturedEvents,
    hasParams: false,
    roles: [1, 7, 14]
  },
  {
    path: "CreatePrivilegesPost",
    title: "Create Privileges Post",
    component: CreatePrivilegesPost,
    hasParams: false,
    roles: [1, 7, 14]
  },
  {
    path: "ViewResource",
    title: "View Resources",
    previousPath: "Resource",
    component: ViewResource,
    hasParams: false,
    roles: [1, 7, 2, 9, 10, 11, 13, 14],
  },
  {
    path: "CreatePreviewResource",
    title: "Create Resource",
    previousPath: "Resource",
    component: CreatePreviewResource,
    hasParams: false,
    roles: [1, 7, 2, 9, 10, 11, 13, 14],
  },
  {
    path: "EditResource/:resourceId",
    title: "Edit Resource",
    component: EditResources,
    hasParams: true,
    roles: [1, 7, 2, 9, 10, 11, 13, 14]
  },
  {
    path: "CreatePrivilegesPost/:privilegesId",
    title: "Create Privileges Post",
    component: CreatePrivilegesPost,
    hasParams: true,
    roles: [1, 7, 14]
  },
  {
    path: "CommunitySpotlight",
    title: "Community Spotlight",
    previousPath: "Website CMS",
    component: CommunitySpotlight,
    hasParams: false,
    roles: [1, 7, 14],
  },
  {
    path: "PreviewResources",
    title: "Preview Resources",
    component: PreviewResources,
    hasParams: false,
    roles: [1, 7, 2, 9, 10, 11, 13, 14],
  },
  {
    path: "CreateResources",
    title: "Create Resources",
    component: CreateResources,
    hasParams: false,
    roles: [1, 7, 2, 9, 10, 11, 13, 14],
  },
  {
    path: "CreateResources/:resourceId",
    title: "Create Resources",
    component: CreateResources,
    hasParams: true,
    roles: [1, 7, 2, 9, 10, 11, 13, 14]
  },
  {
    path: "PostApproval",
    title: "PostApproval",
    previousPath: "Resource",
    component: PostApproval,
    hasParams: false,
    roles: [1, 7, 2, 9, 10, 11, 13, 14],
  },
  {
    path: "PostApproval/:resourceId",
    title: "Post Approval",
    previousPath: "Resource",
    component: PostApproval,
    hasParams: true,
    roles: [1, 7, 2, 9, 10, 11, 13, 14]
  },
  {
    path: "SGTechTv",
    previousPath: "WebCMS",
    title: "SGTechTv",
    component: SGTechTv,
    hasParams: true,
    roles: [1, 7, 14]
  },
  {
    path: "UploadSGTechTV",
    title: "Upload SGTechTV",
    component: UploadSGTechTV,
    hasParams: false,
    roles: [1, 7, 14],
  },
  {
    path: "UploadSGTechTV/:sgTechTVId",
    title: "Upload SGTechTV",
    component: UploadSGTechTV,
    hasParams: true,
    roles: [1, 7, 14]
  },

  {
    path: "bannerUpload/:chapterscommittee",
    previousPath: "Banner Management",
    title: "Banner Upload",
    component: BannerUpload,
    hasParams: true,
    roles: [1, 7, 14]
  },
  {
    path: "bannerUpload/:events",
    previousPath: "Banner Management",
    title: "Banner Upload",
    component: BannerUpload,
    hasParams: true,
    roles: [1, 7, 14]
  },
  {
    path: "bannerUpload/:businessopportunities",
    previousPath: "Banner Management",
    title: "Banner Upload",
    component: BannerUpload,
    hasParams: true,
    roles: [1, 7, 14]
  },
  {
    path: "bannerUpload/:articles",
    previousPath: "Banner Management",
    title: "Banner Upload",
    component: BannerUpload,
    hasParams: true,
    roles: [1, 7, 14]
  },
  {
    path: "bannerUpload/:home",
    previousPath: "Banner Management",
    title: "Banner Upload",
    component: BannerUpload,
    hasParams: true,
    roles: [1, 7, 14]
  },
  {
    path: "Membership/Pricing",
    previousPath: "Membership",
    title: "Membership Pricing",
    component: MembershipPricing,
    hasParams: false,
    roles: [1, 2, 12, 14],
  },
  {
    path: "MembershipCampaign",
    previousPath: "Membership",
    title: "Membership Campaign",
    component: MembershipCampaign,
    hasParams: false,
    roles: [1, 2, 12, 14],
  },
  {
    path: "AddEditMembershipCampaign",
    previousPath: "Membership",
    title: "Edit Membership Campaign",
    component: AddEditMembershipCampaign,
    hasParams: false,
    roles: [1, 2, 12, 14],
  },
  {
    path: "ViewPages",
    title: "View Pages",
    component: ViewPages,
    hasParams: false,
    roles: [1, 7, 2, 9, 10, 11, 12, 13, 14],
  },
  {
    path: "AddEditMembershipCampaign/:membershipCampaignId",
    previousPath: "Membership",
    title: "Edit Membership Campaign",
    component: AddEditMembershipCampaign,
    hasParams: true,
    roles: [1, 2, 12, 14]
  },
  {
    path: "Membership/AuthorityLimit",
    previousPath: "Membership",
    title: "Authority Limit",
    component: AuthorityLimit,
    hasParams: true,
    roles: [1, 4, 2, 12, 14]
  },
  {
    path: "Membership/NotificationAnnouncement",
    previousPath: "Membership",
    title: "Notification Announcement",
    component: NotificationAnnouncemnet,
    hasParams: false,
    roles: [1, 2, 12, 14],
  },
  {
    path: "CreatePages/:MainMenuId/:SubMenuId/:PageNameId",
    title: "Edit Page Details",
    component: CreatePages,
    hasParams: true,
    roles: [1, 7, 2, 9, 10, 11, 12, 13, 14]
  },
  {
    path: "Membership/MembershipTaskList",
    previousPath: "Membership",
    title: "Membership Task List",
    component: MembershipTaskList,
    hasParams: false,
    roles: [1, 2, 12, 14, 15],
  },
  {
    path: "Membership/MembershipFeeApproval",
    previousPath: "Membership",
    title: "Membership Fee Approval",
    component: MembershipFeeApproval,
    hasParams: false,
    roles: [1, 4],
  },
  {
    path: "Membership/ChapterXCommitteeWorkgroupMapping",
    previousPath: "Membership",
    title: "Chapter X Committee Workgroup Mapping",
    component: ChapterXCommitteeWorkgroupMapping,
    hasParams: false,
    roles: [1, 2, 10, 12, 13, 14],
  },
  {
    path: "CreatePages",
    title: "Pages Management",
    component: CreatePages,
    hasParams: false,
    roles: [1, 7, 2, 9, 10, 11, 12, 13, 14],
  },
  // {
  //   path: "EditPages/:MainMenuId/:SubMenuId",
  //   title: "Edit Page Details",
  //   component: CreatePages,
  //   hasParams: ,true,
  // },
  // {
  //   path: "EditPages/:MainMenuId/:SubMenuId/:PageNameId",
  //   title: "Edit Page Details",
  //   component: CreatePages,
  //   hasParams: ,true,
  // },
  {
    path: "EditPages/:PagesDetailsId",
    title: "Edit Page Details",
    component: EditPages,
    hasParams: true,
    roles: [1, 7, 2, 9, 10, 11, 12, 13, 14],
  },
  // {
  //   path: "EditPages/:MainMenuId/:SubMenuId",
  //   title: "Edit Page Details",
  //   component: EditPages,
  //   hasParams: ,true,
  // },
  {
    path: "ViewCorporateMembers",
    previousPath: "Membership",
    title: "View Corporate Members",
    component: ViewCorporateMembers,
    hasParams: false,
    roles: [1, 2, 9, 10, 11, 3, 12, 13, 14, 15,7],
  },
  {
    path: "CreateCorporateMembers",
    previousPath: "Membership",
    title: "Create Corporate Member",
    component: CreateCorporateMembers,
    hasParams: false,
    roles: [1, 2, 9, 10, 11, 3, 12, 13, 14, 15,7],
  },
  {
    path: "CreateCorporateMembers/:CorporateMemberId",
    previousPath: "Membership",
    title: "Edit Corporate Member",
    component: CreateCorporateMembers,
    hasParams: true,
    roles: [1, 2, 9, 10, 11, 3, 12, 13, 14, 15,7]
  },
  {
    path: "IndividualMembers",
    previousPath: "Membership",
    title: "View Individual Members",
    component: IndividualMembers,
    hasParams: false,
    roles: [1, 2, 9, 10, 11, 3, 12, 13, 14, 15,7],
  },
  {
    path: "CreateIndividualMembers",
    previousPath: "Membership",
    title: "Create Individual Members",
    component: CreateIndividualMembers,
    hasParams: false,
    roles: [1, 2, 9, 10, 11, 3, 12, 13, 14, 15,7],
  },
  {
    path: "CreateIndividualMembers/:individualMemberId",
    previousPath: "Membership",
    title: "Create Individual Members",
    component: CreateIndividualMembers,
    hasParams: true,
    roles: [1, 2, 9, 10, 11, 3, 12, 13, 14, 15,7]
  },
  {
    path: "MarketingTrackingURL",
    previousPath: "Entry Management",
    title: "Marketing Tracking URL",
    component: MarketingTrackingURL,
    hasParams: true,
    roles: [1, 7, 9, 10, 11, 12, 2, 13, 14]
  },
  // { path: 'Ac,caTimePicker', title: 'Acca Time Picker' , component: AccaTimePicker, hasParams: false
  // { path: 'Vi,ewEditEntries', previousPath: 'Entry Management', title: 'View / Edit Entries', component: ViewEditEntries, hasParams: true 
  // { path: 'Cr,eateEntry', previousPath: 'Entry Management', title: 'Create Entry', component: CreateEntry, hasParams: true 
  // { path: 'Ca,tegoryCampaignTagging', previousPath: ' ', title: 'Category & Campaign Tagging', component: CategoryCampaignTagging, hasParams: true 
  // { path: 'Pa,rtnerships', previousPath: ' ', title: 'Partnerships', component: Partnerships, hasParams: true 
  // { path: 'Pr,ojectReview', previousPath: 'Government Programme', title: 'Project Review', component: ProjectReview, hasParams: true 
  // { path: 'Ad,dEditGovernmentPartner', previousPath: 'Government Programme', title: 'Add/Edit Government Partner', component: AddEditGovernmentPartner, hasParams: true 
  // { path: 'CreateIndividualMembersNew', previousPath: 'Membership', title: 'Create Individual Members New', component: CreateIndividualMembersNew, hasParams: false },
  // { path: 'CreateIndividualMembersNew/:individualMemberId', previousPath: 'Membership', title: 'Create Individual Members New', component: CreateIndividualMembersNew, hasParams: true },
  // { path: 'AddEditStrategicMember', previousPath: 'Government Programme', title: 'Add/Edit Strategic Member', component: AddEditStrategicMember, hasParams: false },
  // { path: 'AddEditProject', previousPath: 'Government Programme', title: 'Add/Edit Project', component: AddEditProject, hasParams: false },
  // { path: 'CreateAdminAccount', previousPath: 'Account Management', title: 'Create Admin Account', component: CreateAdminAccount, hasParams: false },
  // { path: 'ViewEditAdminAccount', previousPath: 'Account Management', title: 'View/Edit Admin Account', component: ViewEditAdminAccount, hasParams: false },

  {
    path: "ViewEditEntries",
    previousPath: "Entry Management",
    title: "View / Edit Entries",
    component: ViewEditEntries,
    roles: [1, 9, 10, 11, 12, 2, 13, 14],
    hasParams: true,
  },
  {
    path: "EventFeedbackQuestion",
    previousPath: "Entry Management",
    title: "View / Edit Entries",
    component: EventFeedbackQuestion,
    roles: [1, 9, 10, 11, 12, 2, 13, 14],
    hasParams: true,
  },
  {
    path: "FormQuestionAnswer",
    previousPath: "Entry Management",
    title: "View / Edit Entries",
    component: FormQuestionAnswer,
    roles: [1, 9, 10, 11, 12, 2, 13, 14],
    hasParams: true,
  },



  {
    path: "CreateEntry",
    previousPath: "Entry Management",
    title: "Create Entry",
    component: CreateEntry,
    roles: [1, 9, 10, 11, 12, 2, 13, 14],
    hasParams: true,
  },
  {
    path: "CreateEntry/:eventId",
    title: "Update Event Details",
    component: CreateEntry,
    roles: [1, 9, 10, 11, 12, 2, 13, 14],
    hasParams: true,
  },
  {
    path: "CategoryCampaignTagging",
    previousPath: "View/Edit Entry",
    title: "Category & Campaign Tagging",
    component: CategoryCampaignTagging,
    roles: [1, 9, 10, 11, 12, 2, 13, 14],
    hasParams: true,
  },
  {
    path: "CategoryCampaignTagging/:eventId",
    previousPath: "View/Edit Entry",
    title: "Category & Campaign Tagging",
    component: CategoryCampaignTagging,
    roles: [1, 9, 10, 11, 12, 2, 13, 14],
    hasParams: true,
  },


  // {
  //   path: "financial/PaymentReconciliation",
  //   previousPath: "Financial",
  //   title: "Payment Reconciliation",
  //   component: PaymentReconcilation,
  //   roles: [1, 3],
  //   hasParams: false,
  // },



  {
    path: "Partnerships",
    previousPath: " ",
    title: "Partnerships",
    component: Partnerships,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "ProjectReview/:agencyId",
    previousPath: "Government Programme",
    title: "Project Review",
    component: ProjectReview,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "ProjectReview/:isMember/:agencyId",
    previousPath: "Government Programme",
    title: "Project Review",
    component: ProjectReview,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "ProjectReview",
    previousPath: "Government Programme",
    title: "Project Review",
    component: ProjectReview,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "AddEditGovernmentPartner/:AgencyId",
    previousPath: "Government Programme",
    title: "Add/Edit Government Partner",
    component: AddEditGovernmentPartner,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "AddEditGovernmentPartner",
    previousPath: "Government Programme",
    title: "Add/Edit Government Partner",
    component: AddEditGovernmentPartner,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: false,
  },
  {
    path: "CreateIndividualMembersNew",
    previousPath: "Membership",
    title: "Create Individual Members New",
    component: CreateIndividualMembersNew,
    roles: [1, 2, 9, 10, 11, 3, 12, 13, 14],
    hasParams: false,
  },
  {
    path: "CreateIndividualMembersNew/:individualMemberId",
    previousPath: "Membership",
    title: "Create Individual Members New",
    component: CreateIndividualMembersNew,
    roles: [1, 2, 9, 10, 11, 3, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "AddEditStrategicMember",
    previousPath: "Government Programme",
    title: "Add/Edit Strategic Member",
    component: AddEditStrategicMember,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: false,
  },
  {
    path: "AddEditProject",
    previousPath: "Government Programme",
    title: "Add/Edit Project",
    component: AddEditProject,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: false,
  },
  {
    path: "CreateAdminAccount",
    previousPath: "Account Management",
    title: "Create Admin Account",
    component: CreateAdminAccount,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "CreateAdminAccount/:adminId",
    title: "Create Admin Account",
    component: CreateAdminAccount,
    roles: [1, 8],
    hasParams: true,
  },
  {
    path: "ViewEditAdminAccount",
    previousPath: "Account Management",
    title: "View/Edit Admin Account",
    component: ViewEditAdminAccount,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "DropdownManagement",
    previousPath: "Field Management",
    title: "Dropdown Management",
    component: DropdownManagement,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "ManageTags",
    previousPath: "Field Management",
    title: "Manage Tags",
    component: ManageTags,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "EmailTemplateManagement",
    previousPath: "Field Management",
    title: "Email Template Management",
    component: EmailTemplateManagement,
    roles: [1, 8, 2, 10, 7, 9, 12, 13, 14],
    hasParams: false,
  },
  {
    path: "CreateEmailTemplate",
    previousPath: "Field Management",
    title: "Create Email Template",
    component: CreateEmailTemplate,
    roles: [1, 8, 2, 10, 7, 9, 12, 13, 14],
    hasParams: false,
  },

  {
    path: "CreateEmailTemplate/:emailTemplateId",
    previousPath: "Field Management",
    title: "Create Email Template",
    component: CreateEmailTemplate,
    roles: [1, 8, 2, 10, 7, 9, 12, 13, 14],
    hasParams: true,
  },

  {
    path: "EmailHistory",
    previousPath: "Email Management",
    title: "Email History",
    component: EmailHistory,
    roles: [1, 8, 2, 10, 7, 9, 12, 13, 14],
    hasParams: false,
  },
  {
    path: "CreateEmail",
    previousPath: "Email Management",
    title: "Create Email",
    component: CreateEmail,
    roles: [1, 8, 2, 10, 7, 9, 12, 13, 14],
    hasParams: false,
  },

  {
    path: "CreateEmail/:emailManagementId",
    previousPath: "",
    title: "",
    component: CreateEmail,
    roles: [1, 8, 2, 10, 7, 9, 12, 13, 14],
    hasParams: true,
  },

  {
    path: "ManagePages",
    previousPath: "Page Management",
    title: "Manage Pages",
    component: ManagePages,
    roles: [1],
    hasParams: false,
  },
  {
    path: "ChaptersCommitteePrieview",
    previousPath: "Banner Management",
    title: "Chapters Committee Banner",
    component: ChaptersCommitteePrieview,
    roles: [1, 7, 14],
    hasParams: false,
  },
  {
    path: "BannerPreview",
    title: "Banner Preview",
    component: BannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "BannerPreview/:chapterscommittee",
    title: "Banner Preview ",
    component: BannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "BannerPreview/:events",
    title: "Banner Preview ",
    component: BannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "BannerPreview/:businessopportunities",
    title: "Banner Preview ",
    component: BannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "BannerPreview/:articles",
    title: "Banner Preview ",
    component: BannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "ManagePages/:pagesDetailsId",
    previousPath: "Page Management",
    title: "Manage Pages",
    component: ManagePages,
    roles: [1],
    hasParams: true,
  },
  {
    path: "MobileBannerPreview",
    title: "Mobile Banner Preview",
    component: MobileBannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "MobileBannerPreview/:chapterscommittee",
    title: "Mobile Banner Preview",
    component: MobileBannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "MobileBannerPreview/:events",
    title: "Mobile Banner Preview",
    component: MobileBannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "MobileBannerPreview/:businessopportunities",
    title: "Mobile Banner Preview",
    component: MobileBannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "MobileBannerPreview/:articles",
    title: "Mobile Banner Preview",
    component: MobileBannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "MobileBannerPreview/:home",
    title: "Mobile Banner Preview",
    component: MobileBannerPreview,
    roles: [1, 7, 14],
    hasParams: true,
  },
  {
    path: "AddEditProject/:projectId",
    previousPath: "Government Programme",
    title: "Add/Edit Project",
    component: AddEditProject,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "Membership/MembershipDirectoryEnquiry",
    previousPath: "Membership",
    title: "Membership Directory Enquiry",
    component: MembershipDirectoryEnquiry,
    roles: [1, 2, 12, 14],
    hasParams: false,
  },

  {
    path: "Membership/MembershipDirectoryReport",
    previousPath: "Membership",
    title: "Membership Directory Report",
    component: MembershipDirectoryReport,
    roles: [1, 2, 12, 14, 10],
    hasParams: false,
  },

  // Financial
  {
    path: "financial/GlobalSettingFinance",
    previousPath: "Financial",
    title: "Global Setting Finance",
    component: GlobalSettingFinance,
    roles: [1, 3, 8],
    hasParams: false,
  },
  {
    path: "financial/ChartsOfAccounts",
    previousPath: "Financial",
    title: "Charts of Accounts",
    component: ChartsOfAccounts,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/PaymentReconciliation",
    previousPath: "Financial",
    title: "Payment Reconciliation",
    component: PaymentReconcilation,
    roles: [1, 3, 16],
    hasParams: false,
  },
  {
    path: "financial/ReconcilationUploadedData",
    previousPath: "Financial",
    title: "Reconcilation Uploaded Data",
    component: ReconcilationUploadedData,
    roles: [1, 3],
    hasParams: false,
  },
  // {
  //   path: "financial/CreditNoteApprovalProcess",
  //   previousPath: "Financial",
  //   title: "Credit Note Approval Process",
  //   component: CreditNoteApprovalProcess,
  //   roles : [3],
  //   hasParams: false,
  // },
  {
    path: "financial/BatchJobExtraction",
    previousPath: "Financial",
    title: "Batch Job Extraction",
    component: BatchJobExtraction,
    roles: [1, 3, 16],
    hasParams: false,
  },
  {
    path: "financial/XeroInvoiceExtraction",
    previousPath: "Financial",
    title: "Xero Invoice Extraction",
    component: XeroInvoiceExtraction,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/CreditNoteApproval",
    previousPath: "Financial",
    title: "Credit Note Approval",
    component: CreditNoteApproval,
    roles: [1, 3, 4],
    hasParams: false,
  },
  {
    path: "financial/AddEditExternalInvoice",
    previousPath: "Financial",
    title: "External Invoices",
    component: AddEditExternalInvoice,
    roles: [1, 3],
    hasParams: false,
  },
  // {
  //   path: "financial/Accountpayable",
  //   previousPath: "Financial",
  //   title: "Account payable",
  //   component: Accountpayable,
  //   roles: [1, 3],
  //   hasParams: false,
  // },
  // khushbu 27/9 Start
  {
    path: "financial/CreditNoteManagement/:CreditNoteId",
    previousPath: "Financial",
    title: "Credit Note Management ",
    component: CreditNoteManagement,
    roles: [1, 3, 4],
    hasParams: false,
  },
  {
    path: "financial/ExternalInvoices",
    previousPath: "Financial",
    title: "External Invoices ",
    component: ExternalInvoices,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/AddEditExternalInvoice/:financeExternalInvoiceId",
    previousPath: "Financial",
    title: "External Invoices ",
    component: AddEditExternalInvoice,
    roles: [1, 3],
    hasParams: true,
  },
  // {
  //   path: "financial/AddEditExternalInvoice/:financeAdhocInvoiceId",
  //   previousPath: "Financial",
  //   title: "Adhoc Invoices ",
  //   component: AddEditExternalInvoice,
  //   roles : [3],
  //   hasParams: true,
  // },
  {
    path: "financial/ViewBookingDetail/:bookingAttendeeInformationId/:bookingId",
    previousPath: "Financial",
    title: "View Booking Detail ",
    component: ViewBookingDetail,
    roles: [1, 2, 10, 9, 12, 11, 13, 14],
    hasParams: true,
  },
  // khushbu 27/9 End

  {
    path: "financial/VoucherCodeManagement",
    previousPath: "Financial",
    title: "Voucher Code Management",
    component: VoucherCodeManagement,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },

  {
    path: "createVoucherCode/:voucherCodeId",
    previousPath: "VoucherCodeManagement",
    title: "Create Voucher Code",
    component: CreateVoucherCode,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "createVoucherCode",
    previousPath: "VoucherCodeManagement",
    title: "Create Voucher Code",
    component: CreateVoucherCode,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/MembershipReport",
    previousPath: "MembershipReport",
    title: "Membership Report",
    component: MembershipReport,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/VoucherCodeReport",
    previousPath: "VoucherCodeReport",
    title: "Voucher Code Report",
    component: VoucherCodeReport,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/ARAgingReport",
    previousPath: "ARAgingReport",
    title: "ARAging Report",
    component: ARAgingReport,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/GSTReport",
    previousPath: "GSTReport",
    title: "GST Report",
    component: GSTReport,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/TransactionReport",
    previousPath: "Financial",
    title: "Transaction Report",
    component: TransactionReport,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/OrganisationRoleReport",
    previousPath: "Financial",
    title: "Organisation Role Report",
    component: OrganisationRoleReport,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/DeferredIncomeReport",
    previousPath: "Financial",
    title: "Deferred Income Report",
    component: DeferredIncomeReport,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/ChapterMemberReport",
    previousPath: "Financial",
    title: "Chapter Members Report",
    component: ChapterMemberReport,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/ChapterMemberCountTablesReports",
    previousPath: "Financial",
    title: "Chapter Member Count Tables Report",
    component: ChapterMemberCountTablesReports,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/RetentionReport",
    previousPath: "Financial",
    title: "Retention Report",
    component: RetentionReport,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
    hasParams: true,
  },



  // Booking Start
  {
    path: "booking/ViewNewBookings",
    previousPath: "Booking",
    title: "View New Bookings",
    component: ViewNewBookings,
    roles: [1, 2, 10, 9, 12, 11, 13, 14],
    hasParams: false,
  },
  {
    path: "booking/ViewBookingCancellation",
    previousPath: "Booking",
    title: "View New Booking Cancellation",
    component: ViewBookingCancellation,
    roles: [1, 2, 10, 9, 12, 11, 13, 14],
    hasParams: false,
  },
  {
    path: "booking/NewBookingCancellationDetail/:cancellationId",
    previousPath: "Booking",
    title: "New Booking Cancellation Detail",
    component: NewBookingCancellationDetail,
    roles: [1, 2, 10, 9, 12, 11, 13, 14],
    hasParams: false,
  },

  // Un Authorized 
  {
    path: "authentication/UnAuthorized",
    previousPath: "UnAuthorized",
    title: "UnAuthorized",
    component: UnAuthorized,
    roles: [1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14],
    hasParams: false,
  },
  {
    path: "TestEditorPage",
    title: "Test Editor Page",
    component: TestEditorPage,
    hasParams: false,
    roles: [1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14],
  },
  {
    path: "financial/EventRegistrationApproval",
    previousPath: "Financial",
    title: "Event Registration Approval ",
    component: EventRegistrationApproval,
    roles: [1, 9, 10, 11, 13],
    hasParams: false,
  },
  {
    path: "financial/ExtractInvoices",
    previousPath: "Financial",
    title: "Extract Invoices",
    component: ExtractInvoices,
    roles: [1, 3, 16],
    hasParams: false,
  },


  {
    path: "PublicationReport",
    previousPath: "",
    title: "Reports",
    component: PublicationReport,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: false,
  },


  // Go Global Component Import
  {
    path: "goGlobal/GoGlobalContactRequest",
    previousPath: "GoGlobal",
    title: "Go Global Contact Request",
    component: GoGlobalContactRequest,
    roles: [1, 8, 11, 13],
    hasParams: false,
  },
  {
    path: "goGlobal/GoGlobalAccountApproval",
    previousPath: "GoGlobal",
    title: "Go Global Account Approval",
    component: GoGlobalAccountApproval,
    roles: [1, 8, 11, 13],
    hasParams: false,
  },
  {
    path: "goGlobal/ClickAnalytics",
    previousPath: "GoGlobal",
    title: "Click Analytics",
    component: ClickAnalytics,
    roles: [1, 8, 11, 13],
    hasParams: false,
  },
  {
    path: "goGlobal/ViewGoGlobalAccount",
    previousPath: "Go Global",
    title: "View Go Global Account",
    component: ViewGoGlobalAccount,
    roles: [1, 8, 11, 13],
    hasParams: false,
  },

  {
    path: "goGlobal/ViewGoGlobalAccount/:goGlobalMemberId",
    previousPath: "Go Global",
    title: "View Go Global Account",
    component: ViewGoGlobalAccount,
    roles: [1, 8, 11, 13],
    hasParams: true,
  },

  // DTSME
  {
    path: "dtsme/MarketPlace",
    previousPath: "DTSME",
    title: "Market Place",
    component: MarketPlace,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "dtsme/PartnerConsultants",
    previousPath: "DTSME",
    title: "Partner Consultants",
    component: PartnerConsultants,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "dtsme/SolutionProviders",
    previousPath: "DTSME",
    title: "Solution Providers",
    component: SolutionProviders,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "dtsme/DTSMEMentors",
    previousPath: "DTSME",
    title: "DT-SME Mentors",
    component: DTSMEMentors,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "dtsme/AddEditDTSMEMember",
    previousPath: "DTSME",
    title: "Add Edit DTSME Member",
    component: AddEditDTSMEMember,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: false,
  },
  {
    path: "dtsme/AddEditPartnerConsultants",
    previousPath: "DTSME",
    title: "Add Edit Partner Consultant",
    component: AddEditPartnerConsultants,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: false,
  },

  {
    path: "dtsme/AddEditPartnerConsultants/:partnerConsultantInformationId",
    previousPath: "DTSME",
    title: "Add Edit DTSME Member",
    component: AddEditPartnerConsultants,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },
  {
    path: "dtsme/AddEditSolutionProviders/:solutionProviderId",
    previousPath: "DTSME",
    title: "Add Edit DTSME Member",
    component: AddEditSolutionProviders,
    roles: [1,9,2,10,11,12,13,14],
    hasParams: true,
  },



  {
    path: "dtsme/AddEditSolutionProviders",
    previousPath: "DTSME",
    title: "Add Edit Solution Providers",
    component: AddEditSolutionProviders,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: false,
  },
  {
    path: "dtsme/AddEditDTSMEMentors",
    previousPath: "DTSME",
    title: "Add Edit DT-SME Mentors",
    component: AddEditDTSMEMentors,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: false,
  },
  {
    path: "dtsme/AddEditDTSMEMentors/:dtsmeMentorsInformationId",
    previousPath: "DTSME",
    title: "Add Edit DT-SME Mentors",
    component: AddEditDTSMEMentors,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: false,
  },

  {
    path: "dtsme/DTSMEPurchaseReport",
    previousPath: "DTSME",
    title: "DT-SME Purchase Report",
    component: DTSMEPurchaseReport,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: false,
  },

  {
    path: "dtsme/AddEditDTSMEMember/:dtsmeMemberId",
    previousPath: "DTSME",
    title: "Add Edit DTSME Member",
    component: AddEditDTSMEMember,
    roles: [1, 9, 2, 10, 11, 12, 13, 14],
    hasParams: true,
  },

  // Super Report Start
  {
    path: "superReport/CreateReport",
    previousPath: "SuperReport",
    title: "Create Report",
    component: CreateReport,
    roles: [1, 2, 10, 9, 12, 11, 13, 14],
    hasParams: false,
  },

  {
    path: "superReport/RunReport",
    previousPath: "SuperReport",
    title: "Run Report",
    component: RunReport,
    roles: [1, 2, 10, 9, 12, 11, 13, 14],
    hasParams: false,
  },


];
export default ComponentNavigation;